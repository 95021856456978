import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './FeaturedBlog.css';

const FeaturedBlog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios.get('/api/blogs')
      .then(response => setBlogs(response.data))
      .catch(error => console.error(error));
  }, []);

  return (
    <div className="featured-blog-section">
        <div className='container'>
            <div className='justify-content-between mb-4 mb-lg-5 d-none d-md-flex d-lg-flex'>
                <h1 className="section-title mb-0">Featured Blogs</h1>
                <div className='view-all-button-desktop-container d-none d-lg-flex d-md-flex'>
                    <Link
                        to="/blog"
                        className="text-center pointer view-all-btn p-2 px-4 rounded-pill center fw-bold"
                        >
                        View All
                        <svg width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
                            <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
                        </svg>
                    </Link>
                </div>
            </div>
            <h1 className="section-title mb-0 d-block mb-5 d-md-none d-lg-none">Featured Blogs</h1>
            <div className="featured-blogs px-0">
                {blogs.slice(0, 3).map(blog => (
                    <div key={blog.title} className="blog-card">
                        <img className="blog-image" src={blog.image} alt={blog.title} />
                        <div className="blog-content">
                            <h2 className="blog-title">{blog.title}</h2>
                            <p className="blog-category">{blog.category}</p>
                            <p className="blog-description">{blog.description}</p>
                            <div className='w-100 text-end'>
                                <Link 
                                    to={`/blog/${blog.title}`} 
                                    className="read-more-btn text-center py-1 px-3 border border-success rounded-pill mt-4"
                                >
                                    Read More
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='view-all-button-mobile-container d-lg-none d-md-none'>
                <Link
                    to="/blog"
                    className="text-center pointer view-all-btn p-2 mt-5 px-4 rounded-pill center fw-bold"
                    >
                    View All
                    <svg width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
                        <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
                    </svg>
                </Link>
            </div>
        </div>
    </div>
  );
};

export default FeaturedBlog;
