import React, { useState } from "react";
import "./MobileLogin.css";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import CryptoJS from "crypto-js";
import SideMenu from "../../components/Header/SideMenu";
import HeroSvg from "../../components/Layout/HeroSvg";
import { setActiveButton } from "../../redux/features/footerMenuSlice";
import {
  AsteriskSvg,
  CallSvg,
  ChevronLeftSvg,
  EmailSvg,
  EyeSlashSvg,
  EyeSvg,
  ListSvg,
  PasswordSvg,
  PersonSvg,
  SponsorSvg,
} from "../../components/AllSvgModulesExport/LoginSvg";
import axios from "axios";
import GoogleSignIn from "./GoogleSignIn";

const MobileRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sideMenu, setSideMenu] = useState(false);
  const [form, setForm] = useState({
    fname: "",
    email: "",
    mobile: "",
    sponsor: "",
    password: "",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [otp, setOtp] = useState(null);
  const [userEnteredOtp, setUserEnteredOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    fname: false,
    email: false,
    mobile: false,
    password: false,
    isChecked: false,
    otp: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  const handleMobileOTPChange = (e) => {
    const { value } = e.target;
    setUserEnteredOtp(value);
    setErrors((prevErrors) => ({ ...prevErrors, otp: false }));
  };

  const handleConditionChecked = (e) => {
    setIsChecked(e.target.checked);
    setErrors((prevErrors) => ({ ...prevErrors, isChecked: false }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { fname, email, mobile, password } = form;

    const newErrors = {
      fname: !fname,
      email: !email,
      mobile: !mobile,
      password: !password,
      isChecked: !isChecked,
    };
    setErrors(newErrors);

    if (Object.values(newErrors).includes(true)) {
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(form?.email)) {
      message.error("Invalid email format");
      return;
    }
    if (form?.mobile?.length !== 10) {
      message.error("Enter a 10-digit Mobile Number only");
      return;
    }

    setLoading(true);

    try {
      const res = await axios.post("/api/user/send-mobile-otp", form);
      if (res.data.success) {
        setTab(1);

        const {
          otp: encryptedOTP,
          key: encryptedKey,
          iv: encryptedIv,
        } = res.data.data;

        const key = CryptoJS.enc.Hex.parse(encryptedKey);
        const iv = CryptoJS.enc.Hex.parse(encryptedIv);

        // MOBILE OTP
        const decryptedOTP = CryptoJS.AES.decrypt(
          { ciphertext: CryptoJS.enc.Hex.parse(encryptedOTP) },
          key,
          { iv: iv }
        ).toString(CryptoJS.enc.Utf8);
        setOtp(decryptedOTP);
        console.log(decryptedOTP);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      message.error("Registration failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (isNaN(userEnteredOtp)) {
      return message.error("Enter only numbers");
    }

    if (userEnteredOtp !== otp) {
      setErrors((prevErrors) => ({ ...prevErrors, otp: true }));
      message.error("Invalid OTP. Please try again.");
      return;
    }

    // Proceed with registration logic here
    // Example:
    // try {
    //   await axios.post('/api/user/register', form);
    //   message.success('Registration successful!');
    //   navigate('/welcome');
    // } catch (error) {
    //   message.error('Registration failed. Please try again.');
    // }
    setLoading(true);

    try {
      const response = await axios.post("/api/user/register", form);
      if (response.data.success) {
        message.success("Registration successful!");
        navigate("/login");
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    dispatch(setActiveButton("home"));
    navigate("/");
    localStorage.setItem("activeButton", "home");
  };

  return (
    <>
      <div className="account-modal-container d-lg-none d-md-none">
        <HeroSvg />
        <div className="account-modal-container-content">
          <div className="top-left-content">
            <div className="box-1 center" onClick={onClose}>
              <ChevronLeftSvg />
            </div>
            <h2 className="mb-0">Register</h2>
          </div>
          <div
            className="top-right-content box-2 center"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <ListSvg />
          </div>
        </div>

        <div className="mobile-login-container px-3 mt-3">
          <div className="mobile-login-content px-3">
            {tab === 0 && (
              <form
                className="content"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <h1 className="mb-0 pt-2">Create Account !!</h1>
                <p>Fill up the form</p>

                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                  <div className="form-input">
                    <input
                      autoComplete="off"
                      name="fname"
                      value={form.fname}
                      type="text"
                      placeholder="Full name"
                      id="fname"
                      className={`form-control text-muted rounded-xs ${
                        errors.fname && "is-invalid"
                      }`}
                      onChange={handleChange}
                    />
                    <div className="invalid-feedback">
                      Please enter your full name
                    </div>
                    <PersonSvg />
                  </div>
                </div>

                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                  <div className="form-input">
                    <input
                      autoComplete="off"
                      value={form.email}
                      name="email"
                      type="email"
                      placeholder="Email"
                      id="email"
                      className={`form-control text-muted rounded-xs ${
                        errors.email && "is-invalid"
                      }`}
                      onChange={handleChange}
                    />
                    <EmailSvg />
                    <div className="invalid-feedback">
                      Please enter a valid email id
                    </div>
                  </div>
                </div>

                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                  <div className="form-input">
                    <input
                      autoComplete="off"
                      value={form.mobile}
                      name="mobile"
                      type="text"
                      placeholder="Mobile"
                      id="mobile"
                      className={`form-control text-muted rounded-xs ${
                        errors.mobile && "is-invalid"
                      }`}
                      onChange={handleChange}
                    />
                    <CallSvg />
                    <div className="invalid-feedback">
                      Please enter a valid mobile number
                    </div>
                  </div>
                </div>

                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                  <div className="form-input">
                    <input
                      autoComplete="off"
                      value={form.sponsor}
                      name="sponsor"
                      type="text"
                      placeholder="Enter Sponsor Code (Optional)"
                      id="sponsor"
                      className="form-control text-muted rounded-xs"
                      onChange={handleChange}
                    />
                    <SponsorSvg />
                  </div>
                </div>

                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                  <div className="form-input">
                    <input
                      autoComplete="off"
                      value={form.password}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      id="password"
                      className={`form-control text-muted rounded-xs ${
                        errors.password && "is-invalid"
                      }`}
                      onChange={handleChange}
                    />
                    <PasswordSvg />
                    <div
                      className="password-toggle-icon"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeSlashSvg /> : <EyeSvg />}
                    </div>
                    <div className="invalid-feedback">
                      Please enter a password
                    </div>
                  </div>
                </div>

                <div className="form-check form-check-custom">
                  <input
                    className={`form-check-input ${isChecked && "bg-success"}`}
                    type="checkbox"
                    id="c22"
                    checked={isChecked}
                    onChange={handleConditionChecked}
                  />
                  <label className="form-check-label" htmlFor="c22">
                    <small>
                      I agree to all{" "}
                      <Link to="/terms">terms &amp; conditions</Link>
                    </small>
                  </label>
                  {errors.isChecked && (
                    <div className="invalid-feedback d-block">
                      You must agree to the terms &amp; conditions
                    </div>
                  )}
                </div>

                <button
                  className="mobile-login-button mt-3"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "REGISTER"
                  )}
                </button>

                <div className="divider-container">
                  <hr className="divider-line mx-5" />
                  <div className="text-center divider-text">or</div>
                  <hr className="divider-line mx-5" />
                </div>
                <div className="text-center d-flex justify-content-center align-items-center ">
                  <GoogleSignIn />
                </div>

                <div className="mobile-login-form-footer-content mt-3 d-flex justify-content-between align-items-center px-2">
                  <p className="mb-0 text-start">
                    Already registered? <Link to="/login">Login</Link>
                  </p>
                </div>
              </form>
            )}

            {tab === 1 && (
              <form
                className="content"
                autoComplete="off"
                onSubmit={handleRegister}
              >
                <h1 className="mb-0 pt-2">Verification !!</h1>
                <p>Enter Mobile OTP</p>

                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                  <div className="form-input">
                    <input
                      autoComplete="off"
                      name="mobile-otp"
                      value={userEnteredOtp}
                      type="text"
                      placeholder="Enter OTP"
                      id="mobile-otp"
                      className={`form-control text-muted rounded-xs ${
                        errors.otp && "is-invalid"
                      }`}
                      onChange={handleMobileOTPChange}
                    />
                    <AsteriskSvg />
                    <div className="invalid-feedback">Please enter the OTP</div>
                  </div>
                </div>

                <button
                  className="mobile-login-button mt-3"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Verify And Register"
                  )}
                </button>

                <div className="mobile-login-form-footer-content mt-3 d-flex justify-content-between align-items-center px-2">
                  <p className="mb-0 text-start">
                    Already registered? <Link to="/login">Login</Link>
                  </p>
                </div>
              </form>
            )}
          </div>
          <span className="center mt-4">
            <small>
              <p className="mb-0 fw-bold">
                All Rights Reserved © 2024 | TOPUP PLAYGROUND
              </p>
              <p className="mb-0">
                <span>Website designed & developed by</span>
                <Link
                  target="_blank"
                  className="text-danger"
                  to="https://aashirdigital.com/"
                >
                  ~@aashirdigital
                </Link>
              </p>
            </small>
          </span>
        </div>
      </div>

      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  );
};

export default MobileRegister;
