import React, { useEffect, useRef, useState } from "react";
import "./AddMoneyModal.css";
import { message } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import { submitPaymentForm } from "../../pages/ProductInfoMobile/submitPaymentForm";
import { RupeesIcon } from "../Header/AccountModalSvg";

const AddMoneyModal = ({ showAddMoneyModal, setShowAddMoneyModal }) => {
  const { user } = useSelector((state) => state.user);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [gateway, setGateway] = useState("BARCODE");

  // Create a ref for the input field
  const amountInputRef = useRef(null);

  const closeModal = (e) => {
    e.stopPropagation();
    setShowAddMoneyModal(false);
  };

  const handleOnChange = (e) => {
    setAmount(e.target.value);
    if (e.target.value !== "0" && e.target.value.trim() !== "") {
      setError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const amountNumber = Number(amount);
    if (isNaN(amountNumber)) {
      return message.error("Please enter amount in number");
    }
    if (amount === "" || amount === "0") {
      return setError(true);
    } else {
      if (gateway === "BARCODE") {
        handleBarcode();
      } else if (gateway === "PAYU") {
        handlePayu();
      }
    }
  };

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
  
    setOrderId(orderId);
  };
  

  useEffect(() => {
    generateOrderId();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    amountInputRef.current.focus();
    // eslint-disable-next-line
  }, []);

  async function handlePayu() {
    setLoading(true);
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: amount,
        product_name: user?.sponsor || "noSponsor",
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        success_URL: "https://topupplayground.com/api/wallet/success",
        failure_URL: "https://topupplayground.com/api/wallet/failure",
        // success_URL: "http://localhost:8080/api/wallet/success",
        // failure_URL: "http://localhost:8080/api/wallet/failure",
        udf1: "",
        udf2: "",
        udf3: "",
        udf4: "",
        udf5: "",
      };

      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoading);
      }, 1500);
    } catch (error) {
      console.error("Error initiating payment:", error);
    } finally{
      setLoading(false);
    }
  }

  async function handleBarcode() {
    if (amount < 1) {
      return message.error("Enter amount greater than 20");
    }
    setLoading(true);
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: amount,
        txn_note: "Wallet Topup Note",
        product_name: user?.sponsor || "noSponsor",
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/wallet/status?orderId=${orderId}`,
      };
      const response = await axios.post("/api/wallet/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        console.log(response.data.message);
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  }

  return (
    <div
      className={`add-money-container d-lg-none d-md-none ${
        showAddMoneyModal ? "active" : ""
      }`}
      onClick={closeModal}
    >
      <div
        className={`add-money-content ${showAddMoneyModal ? "active" : ""} p-3`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mt-2 py-1 w-100">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="mb-0">Add Money</h1>
            <button
              className="bg-none border-0 center p-2 rounded-pill"
              data-bs-dismiss="offcanvas"
              onClick={closeModal}
            >
              <svg
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            </button>
          </div>
          <form
            className="content mt-0 p-0 mt-3"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <div className="form-custom">
              
              <div className="position-relative mb-2">
                <input
                  type="number"
                  autoComplete="off"
                  name="amount"
                  placeholder="Enter your amount"
                  id="amount"
                  className={`w-100 form-control ${error && "is-invalid"}`}
                  value={amount}
                  onChange={handleOnChange}
                  ref={amountInputRef} // Attach ref to the input
                />
                <div className="add-money-modal-rupees-icon">
                  <RupeesIcon />
                </div>
              </div>
              {error && (
                <small className="text-danger">Please enter an amount</small>
              )}
            </div>
            <div className="pb-3"></div>
            {!loading?
              <button
                className="btn btn-full gradient-blue shadow-bg shadow-bg-s w-100"
                type="submit"
              >   
                  CONTINUE
                  {/* Wallet is Temporarily Disabled */}
              </button>
              :
              <button disabled className="btn btn-full gradient-blue shadow-bg shadow-bg-s w-100">
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden"></span>
                </div>
              </button>
            }
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddMoneyModal;
