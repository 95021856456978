import React, { useEffect, useRef, useState } from "react";
import "./SupportModal.css";
import HeroSvg from "../Layout/HeroSvg";
import MobileHeader from "./MobileHeader";
import NotLoginPopupModal from "../Modal/NotLoginPopupModal";
import axios from "axios";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setActiveButton,
  setShowSupportModal,
} from "../../redux/features/footerMenuSlice";
import SideMenu from "./SideMenu";
import { ChevronLeftSvg } from "../AllSvgModulesExport/LoginSvg";
import { RefreshSvg } from "./AccountModalSvg";

const SupportModal = ({ show, user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const textAreaRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState(false);
  const [query, setQuery] = useState([]);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);

  const submitButtonRef = useRef(null);
  const [tab, setTab] = useState(0);
  const [singleQuery, setSingleQuery] = useState(null);
  const [singleQueryLoading, setSingleQueryLoading] = useState(false);
  const [allQueries, setAllQueries] = useState(null);
  const [allQueriesLoading, setAllQueriesLoading] = useState(false);

  async function handleSubtmit(id) {
    if(!query){
      return message.error('please add some query')
    }
    try {
      const res = await axios.post("/api/contact/update-query", {
        id: id,
        msg: query,
        person: "user",
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      if (res.data.success) {
        message.success(res.data.message);
        const updatedQuery = { ...singleQuery, msg: [...singleQuery.msg, { msg: query, person: "user" }] };
        setSingleQuery(updatedQuery);
        setQuery("");
        getUserQuery();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getSingleQuery = async (id)=> {
    setSingleQueryLoading(true);
    try {
      const res = await axios.post("/api/contact/get-single-query", {
        id: id
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      if (res.data.success) {
        message.success(res.data.message);
        const updatedQuery = res.data.data;
        setSingleQuery(updatedQuery);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSingleQueryLoading(false);
    }
  }

  async function getUserQuery() {
    try {
      setAllQueriesLoading(true);
      const res = await axios.post("/api/contact/get-user-query", {
        email: user?.email,
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      if (res.data.success) {
        setAllQueries(res.data.data.reverse());
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAllQueriesLoading(false);
    }
  }

  const copyToClipboard = (event, text) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("Ticket ID copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy!");
        console.error("Failed to copy: ", err);
      });
  };

  useEffect(() => {
    if (user !== null) {
      getUserQuery();
    }
    // eslint-disable-next-line
  }, [user]);

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const onQueryChange = (e) => {
    setQuery([
      {
        msg: e.target.value,
        person: "user",
      },
    ]);
  };

  const onClose = () => {
    dispatch(setShowSupportModal(false));
    dispatch(setActiveButton("home"));
    localStorage.setItem('activeButton', 'home');
  };

  const generateTicketId = () => {
    const now = new Date();
    const date = now.toISOString().split('T')[0].replace(/-/g, ''); // Format: YYYYMMDD
    const time = now.toTimeString().split(' ')[0].replace(/:/g, ''); // Format: HHMMSS
    const randomNumber = Math.floor(Math.random() * 1000); // Generate a random number
  
    return `${date}${time}${randomNumber}`; // Combine to form the ticket ID
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (query?.length === 0) {
      return message.error("please enter your querry");
    }
    if (!type) {
      return message.error("please select querry type");
    }

    const form = {
      name: user?.fname,
      email: user?.email,
      mobile: user?.mobile,
      msg: query,
      ticketId: generateTicketId(), 
      type: type,
    };
    setLoading(true);
    try {
      const res = await axios.post("/api/contact/add-contact-form", form, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setQuery("");
        setState(false);
        dispatch(setShowSupportModal(false));
        navigate("/all-queries");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const contactUsClick = () => {
    if (!user) {
      setShowModal(true);
    } else {
      setState(true);
    }
  };

  useEffect(() => {
    if (state && textAreaRef.current) {
      textAreaRef.current.focus();
    }
    // eslint-disable-next-line
  }, [state]);

  const viewAllQueriesClick = ()=>{
    window.location.href = "/all-queries"
    dispatch(setActiveButton('more'));
  }

  if (!show) {
    return null;
  }

  const accordionItems = [
    {
      id: "accordion5-1",
      icon: (
        <svg
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-heart-fill text-danger"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
          />
        </svg>
      ),
      title: "24/7 Chat Support",
      content: `At our Topupplayground Official, we take pride in offering an
                unparalleled level of customer support through our 24/7
                Customer Support Service. Whenever you have questions
                or need assistance navigating our Topupplayground official, our
                dedicated team is here to help. Whether you're
                experiencing technical issues, have inquiries about our
                services, or simply need guidance, we're committed to
                providing prompt and reliable support to ensure your
                experience is seamless and satisfactory. You can trust
                us to be there for you every step of the way, offering
                personalized assistance tailored to your needs and
                ensuring that you can recharge with ease.`,
    },
    {
      id: "accordion5-2",
      icon: (
        <svg
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-fingerprint"
          viewBox="0 0 16 16"
        >
          <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z" />
          <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332q0 .613-.066 1.221A.5.5 0 0 1 6 8.447q.06-.555.06-1.115zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8m-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329" />
          <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334m.3 1.67a.5.5 0 0 1 .449.546 10.7 10.7 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.7 9.7 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z" />
          <path d="M3.902 4.222a5 5 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 4 4 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556m6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705M3.68 5.842a.5.5 0 0 1 .422.568q-.044.289-.044.59c0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.5 6.5 0 0 0 3.058 7q0-.375.054-.736a.5.5 0 0 1 .568-.422m8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.5 10.5 0 0 0 .584-2.678.5.5 0 0 1 .54-.456" />
          <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865m-.89 1.257a.5.5 0 0 1 .04.706A5.48 5.48 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346m12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49" />
        </svg>
      ),
      title: "Secure Payments",
      content: `We prioritize the security of your transactions. Our platform employs
                advanced encryption technologies and secure payment gateways to
                ensure that your financial information is protected. Shop with
                confidence knowing that your data is safe and secure.`,
    },
    // Add more items here as needed
  ];

  return (
    <>
      <div className="support-modal-container d-lg-none d-md-none">
        <HeroSvg />
        <div className="account-modal-container-content">
          <div className="top-left-content">
            <div className="box-1 center" onClick={onClose}>
              <svg
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                />
              </svg>
            </div>
            <h2 className="mb-0">Contact Support</h2>
          </div>
          <div
            className="top-right-content box-2 center"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <svg
              width="18"
              height="18"
              fill="currentColor"
              className="bi bi-list text-white"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
              />
            </svg>
          </div>
        </div>

        <div className="support-modal-bottom-container px-3">
          <div className="support-modal-bottom-content px-3 pb-3">
            <div className="card-bottom p-3">
              <h1 className="text-white mb-0">24/7 Customer Support</h1>
              <p className="text-white mb-0 opacity-60">
                Provided by our Official Team.
              </p>
            </div>
            <div className="card-overlay bg-gradient-fade"></div>
          </div>
        </div>

        {!state ? (
          <div className="support-modal-bottom-container px-3">
            <div className="support-modal-bottom-content-issue-facing p-3">
              <h6 className="py-2 mb-0">What issues are you facing?</h6>
              <div className="content">
                <div className="accordion accordion-m" id="accordion-group-5">
                  {accordionItems.map((item) => (
                    <div className="accordion-item" key={item.id}>
                      <button
                        className="accordion-button px-0 ps-1 gap-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${item.id}`}
                        aria-expanded="true"
                      >
                        {item.icon}
                        <span className="font-600 font-14">{item.title}</span>
                      </button>
                      <div
                        id={item.id}
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordion-group-5"
                      >
                        <p className="mb-0 pb-3">{item.content}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="support-modal-bottom-container px-3">
            <div className="support-modal-bottom-content-issue-facing p-3">
              <div className="d-flex justify-content-start align-items-center gap-3">
                <div
                  className="box-1"
                  onClick={() => {
                    setState(false);
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-left text-white fw-bold"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                    />
                  </svg>
                </div>
                <h6 className="py-2 mb-0">Please enter your query here.</h6>
              </div>
              <textarea
                className="text-area mt-4 shadow-sm border px-3 py-2"
                name="query"
                id=""
                rows="5"
                cols="50"
                ref={textAreaRef}
                onChange={onQueryChange}
              ></textarea>
              <select
                className="form-select shadow-sm border px-3 py-2"
                onChange={(e) => {
                  setType(e.target.value);
                }}
                value={type}
              >
                <option value="">Select type of query</option>
                <option value="Payment Related Query">
                  Payment Related Queries
                </option>
                <option value="In-Game Recharge Query">
                  In-Game Recharge Query
                </option>
                <option value="Wanted to be a Reseller">
                  Wanted to be a Reseller
                </option>
                <option value="others">Other Query</option>
              </select>
              <button
                type="submit"
                className="contact-us-button w-100 mt-4"
                onClick={onSubmit}
              >
                {loading ? "Loading..." : "SUBMIT YOUR QUERY"}
              </button>
            </div>
          </div>
        )}

        <div className="px-3">
          {!state && (
            <button
              type="submit"
              className="contact-us-button w-100"
              onClick={contactUsClick}
            >
              RAISE TICKET 📝
            </button>
          )}
        </div>



        {user && <div className="px-3 mt-3">
          {tab === 0 && <div className="all-queries-content py-3 bg-transparent">
                <p className="fw-bold fs-6 text-center" style={{fontFamily: "system-ui", opacity: 0.6}}>Recent Queries</p>
                {!allQueriesLoading?
                  <div className='bg-white rounded-4 shadow-sm border'>
                    {allQueries?
                      allQueries?.slice(0, 5)?.map((item, index) => {
                        return (
                          <div key={item?._id} className='all-queries-content-item py-3 px-3 border-bottom'>
                            <div className='center gap-2'>
                              <div className='index-number text-white center'>{index + 1}</div>
                              <div className='fw-bold all-queries-content-item-type'>
                                <div className='item-type mb-1'>{item?.type}</div>
                                <div
                                  className="d-flex justify-content-start align-items-center rounded gap-1"
                                  onClick={(event) => {
                                    copyToClipboard(event, `Ticket Id: ${item?.ticketId}`);
                                  }}
                                  style={{ display: 'inline-flex' }} // Add this line
                                >
                                  <ContentCopyIcon className="fs-6 ticket-id" />
                                  <span className="ticket-id">{item?.ticketId || 'id unavailable'}</span> {/* Adjusted to use the dynamic ticketId */}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                className='center'
                                onClick={() => {
                                  setTab(1);
                                  setSingleQuery(item);
                                }}
                              >
                                {item?.status === "seen" ?
                                <div className='d-flex flex-column justify-content-center align-items-end'>
                                  <div className='d-flex justify-content-end align-items-center gap-2 flex-wrap-reverse'>
                                    <div className='fw-bold text-dark ticket-id'>
                                        {item?.created ? new Date(item.created).toLocaleDateString('en-GB', {
                                        day: 'numeric',
                                        month: 'short'
                                      }) : ''}
                                    </div>
                                    <svg width="18" height="18" fill="currentColor" className="text-danger" viewBox="0 0 16 16">
                                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                                    </svg>
                                  </div>
                                  <div className='fw-bold text-danger'>
                                    <small>closed</small>
                                  </div>
                                </div>
                                :
                                <div className='d-flex flex-column justify-content-center align-items-end'>
                                  <div className='d-flex justify-content-end align-items-center gap-2 flex-wrap-reverse'>
                                    <div className='fw-bold text-dark ticket-id'>
                                      {item?.created ? new Date(item.created).toLocaleDateString('en-GB', {
                                        day: 'numeric',
                                        month: 'short'
                                      }) : ''}
                                    </div>
                                    <svg width="18" height="18" fill="currentColor" className="text-success" viewBox="0 0 16 16">
                                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                                    </svg>
                                  </div>
                                  <div className='fw-bold text-success'>
                                    <small>open</small>
                                  </div>
                                </div>
                                }
                              </div>
                            </div>
                          </div>
                        );
                      })
                      : 
                      <div className="center p-5 border bg-white rounded-4">
                        <div>No Queries Found</div>
                      </div>
                    }
                  </div>
                  : 
                  <div className='text-center p-3'>
                    <div class="spinner-border text-secondary" role="status">
                      <span class="visually-hidden"></span>
                    </div>
                  </div>
                }
          </div>}

          {(tab === 0 && allQueries) && <div className="center" onClick={viewAllQueriesClick}>
            <div className="fw-bold bg-white rounded-4 p-2 px-3 shadow-sm" style={{opacity: '0.9', fontSize: '14px'}}>View All Queries</div>
          </div>}

          {tab === 1 &&  <div className="all-queries-content bg-white shadow-sm border p-3 py-4 position-relative rounded-4">
            <div className="position-absolute d-flex justify-content-center align-items-center gap-2">
              <div className="shadow-sm bg-light border center rounded-3" onClick={()=>{setTab(0)}} style={{width: '40px', height: "40px"}}>
                <ChevronLeftSvg/>
              </div>
              {!singleQueryLoading? <div className="shadow-sm border bg-light center rounded-3" onClick={()=>{getSingleQuery(singleQuery?._id)}} style={{width: '40px', height: "40px"}}>
                <RefreshSvg />
              </div> :
              "Loading..."
              }
            </div>
            <div className="query-reply-container mt-5">
              {singleQuery?.msg?.map((item, index) => {
                return (
                  <div key={index}
                    className={`query-msg ${
                      item?.person === "user" && "active"
                    }`}
                  >
                    <span>{item?.msg}</span>
                  </div>
                );
              })}
              {singleQuery?.status === "pending" && (
                <textarea
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="my-3 form-control shadow-sm border px-3 py-2"
                  rows="4"
                >
                </textarea>
              )}

              {singleQuery?.status === "seen" ? (
                <>
                  <button
                    onClick={() => handleSubtmit(singleQuery?._id)}
                    className="closed-button mt-3"
                    disabled={singleQuery?.status === "seen"}
                  >
                    Admin Has Closed this ticket
                  </button>
                </>
              ) : (
                <button
                  ref={submitButtonRef}
                  onClick={() => handleSubtmit(singleQuery?._id)}
                  className="update-button mt-3"
                >
                  Submit
                </button>
              )}
            </div>
          </div>}
        </div>}




        <span className="center mt-4">
          <small>
            <p className="mb-0 fw-bold">
              All Rights Reserved © 2024 | TOPUP PLAYGROUND
            </p>
            <p className="mb-0">
              <span>Website designed & developed by</span>
              <Link
                target="_blank"
                className="text-danger"
                to="https://aashirdigital.com/"
              >
                ~@aashirdigital
              </Link>
            </p>
          </small>
        </span>
      </div>
      <NotLoginPopupModal
        string="To use the chat support, please login first."
        show={showModal}
        onClose={() => setShowModal(false)}
      />
      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  );
};

export default SupportModal;
