import React, { useState } from "react";
import "./MobileLogin.css";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import SideMenu from "../../components/Header/SideMenu";
import HeroSvg from "../../components/Layout/HeroSvg";
import { setActiveButton } from "../../redux/features/footerMenuSlice";
import {
  ChevronLeftSvg,
  EmailSvg,
  ListSvg,
  PasswordSvg,
  EyeSvg,
  EyeSlashSvg,
} from "../../components/AllSvgModulesExport/LoginSvg";
import axios from "axios";
import getUserData from "../../utils/userDataService";
import GoogleSignIn from "./GoogleSignIn";

const MobileLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sideMenu, setSideMenu] = useState(false);
  const [form, setForm] = useState({ email: "", password: "" });
  const [isChecked, setisChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    isChecked: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  const handleConditionChecked = (e) => {
    setisChecked(e.target.checked);
    setErrors((prevErrors) => ({ ...prevErrors, isChecked: false }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = form;

    const newErrors = {
      email: !email,
      password: !password,
      isChecked: !isChecked,
    };
    setErrors(newErrors);

    if (!email || !password || !isChecked) {
      return;
    }

    // Implement your login logic here
    if (form?.email === "aashirdigital@gmail.com") {
      try {
        setLoading(true);
        const res = await axios.post("/api/user/admin", form);
        if (res.data.success) {
          setLoading(false);
          localStorage.setItem("token", res.data.token);
          navigate("/admin-dashboard");
        } else {
          setLoading(false);
          message.error(res.data.message);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      try {
        setLoading(true);
        const res = await axios.post("/api/user/login", form);
        if (res.data.success) {
          setTimeout(() => {
            setLoading(false);
            localStorage.setItem("token", res.data.token);
            dispatch(setActiveButton("home"));
            navigate("/");
            getUserData(dispatch);
          }, 1500);
        } else {
          setLoading(false);
          message.error(res.data.message);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        message.error("Something went wrong");
      }
    }
  };

  const onClose = () => {
    dispatch(setActiveButton("home"));
    navigate("/");
    localStorage.setItem("activeButton", "home");
  };

  return (
    <>
      <div className={`account-modal-container d-lg-none d-md-none `}>
        <HeroSvg />
        <div className="account-modal-container-content">
          <div className="top-left-content">
            <div className="box-1 center" onClick={onClose}>
              <ChevronLeftSvg />
            </div>
            <h2 className="mb-0">Login</h2>
          </div>
          <div
            className="top-right-content box-2 center"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <ListSvg />
          </div>
        </div>

        <div className="mobile-login-container px-3 mt-3">
          <div className="mobile-login-content px-3">
            <form
              className="content"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <h1 className="mb-0 pt-2">Welcome back !!</h1>
              <p>Fill up the form</p>
              <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                <div className="form-input">
                  <input
                    type="email"
                    autoComplete="off"
                    name="email"
                    placeholder="Your email"
                    id="email"
                    className={`form-control text-muted rounded-xs ${
                      errors.email && "is-invalid"
                    }`}
                    value={form.email}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback">
                    Please enter your email id
                  </div>
                  <EmailSvg />
                </div>
              </div>

              <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                <div className="form-input">
                  <input
                    type={showPassword ? "text" : "password"} // Toggle between text and password
                    autoComplete="off"
                    name="password"
                    placeholder="Your password"
                    id="password"
                    className={`form-control text-muted rounded-xs ${
                      errors.password && "is-invalid"
                    }`}
                    value={form.password}
                    onChange={handleChange}
                  />
                  <PasswordSvg />
                  <div
                    className="password-toggle-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <EyeSlashSvg /> : <EyeSvg />}
                  </div>
                  <div className="invalid-feedback">Please enter password</div>
                </div>
              </div>
              <div className="form-check form-check-custom">
                <input
                  className={`form-check-input ${isChecked && "bg-success"}`}
                  type="checkbox"
                  id="c22"
                  checked={isChecked}
                  onChange={handleConditionChecked}
                />
                <label className="form-check-label" htmlFor="c22">
                  <small>
                    I agree to all{" "}
                    <Link to="/terms">terms &amp; conditions</Link>
                  </small>
                </label>
                {errors.isChecked && (
                  <div className="invalid-feedback d-block">
                    You must agree to the terms &amp; conditions
                  </div>
                )}
              </div>
              <button className="mobile-login-button mt-3" type="submit">
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden"></span>
                  </div>
                ) : (
                  "LOGIN"
                )}
              </button>
              <div className="divider-container">
                <hr className="divider-line mx-5" />
                <div className="text-center divider-text">or</div>
                <hr className="divider-line mx-5" />
              </div>
              <div className="text-center d-flex justify-content-center align-items-center ">
                <GoogleSignIn />
              </div>
              <div className="mobile-login-form-footer-content mt-3 d-flex justify-content-between align-items-center px-2">
                <p className="mb-0 text-start">
                  New here? <Link to="/register">Register</Link>
                </p>
                <p className="mb-0 text-end">
                  Forgot your password?{" "}
                  <Link to="/forgot-password">Click here</Link>
                </p>
              </div>
            </form>
          </div>
          <span className="center mt-4">
            <small>
              <p className="mb-0 fw-bold">
                All Rights Reserved © 2024 | TOPUP PLAYGROUND
              </p>
              <p className="mb-0">
                <span>Website designed & developed by</span>
                <Link
                  target="_blank"
                  className="text-danger"
                  to="https://aashirdigital.com/"
                >
                  ~@aashirdigital
                </Link>
              </p>
            </small>
          </span>
        </div>
      </div>

      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  );
};

export default MobileLogin;
