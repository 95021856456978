import React, { useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";

const ProductInfoFinalPopup = ({
  showModal,
  userId,
  setShowModal,
  product,
  playerCheck,
  selectedPrice,
  amount,
  checkPlaceOrder,
  loader,
}) => {
  const { user } = useSelector((state) => state.user);
  const [mode, setMode] = useState(""); // this state is only for button loading purpose
  const [transactionId, setTransactionId] = useState(""); // this state is only for button loading purpose

  const generateOrderId = (e, paymentMode) => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
    setTransactionId(orderId);
    if (orderId) {
      return checkPlaceOrder(e, paymentMode, orderId);
    }
  };

  const closeModal = (e) => {
    e.stopPropagation();
    setShowModal(false);
  };

  const handlePaymentButtonClick = (e, paymentMode) => {
    setMode(paymentMode);
    generateOrderId(e, paymentMode);
  };

  return (
    <div
      className={`product-info-final-popup-container d-lg-none d-md-none ${
        showModal ? "active" : ""
      }`}
      onClick={closeModal}
    >
      <div
        className={`product-info-final-popup-content center flex-column ${
          showModal ? "active" : ""
        } p-3`}
        onClick={(e) => e.stopPropagation()}
      >
        <svg
          width="65"
          height="65"
          fill="currentColor"
          className="product-info-final-popup-content-question-mark question-mark-animated mt-4"
          viewBox="0 0 16 16"
        >
          <path d="M5.933.87a2.89 2.89 0 0 1 4.134 0l.622.638.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01zM7.002 11a1 1 0 1 0 2 0 1 1 0 0 0-2 0m1.602-2.027c.04-.534.198-.815.846-1.26.674-.475 1.05-1.09 1.05-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.7 1.7 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745.336 0 .504-.24.554-.627" />
        </svg>
        <h1 className="pt-3 font-28">Are you sure!</h1>
        <p className="font-14">Do you wanna continue with this order?</p>
        <div className="product-info-modal-card p-4 mb-3">
          <div className="checkout-product-content mb-3 gap-2">
            <div className="product-image">
              <img src={`/${product?.image}`} alt="loading-img" loading="lazy"/>
            </div>
            <div className="align-self-center ps-1">
              <h5 className="mb-1">{product?.name}</h5>
              <p
                className={`mb-0 font-14 opacity-70 ${
                  playerCheck ? "text-success" : "text-dark"
                }`}
              >
                {playerCheck ? (
                  <>
                    {playerCheck?.toUpperCase()}
                    <svg
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-patch-check-fill ms-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                    </svg>
                  </>
                ) : (
                  "N.A"
                )}
              </p>
            </div>
          </div>
          <div className="row">
            <strong className="col-5  text-start">Item</strong>
            <strong className="col-7 text-end">{amount}</strong>
            <hr className="my-2 text-secondary" />
            <strong className="col-5 text-start">User Id</strong>
            <strong className="col-7 text-end wrap">{userId}</strong>
            <hr className="my-2 text-secondary" />
            <strong className="col-5 text-start">Amount</strong>
            <strong className="col-7 text-end color-highlight">
              ₹{selectedPrice}
            </strong>
            <hr className="my-2 text-secondary" />
          </div>
        </div>
        <div className="payment-buttons">
          <button
            className="border wallet-btn center"
            onClick={(e) => {
              handlePaymentButtonClick(e, "WALLET");
            }}
            disabled={loader}
          >
            PAY VIA WALLET
            {loader && mode === "WALLET" && (
              <div className="ms-2 spinner-grow spinner-grow-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
          <button
            className="border upi-btn center"
            onClick={(e) => {
              handlePaymentButtonClick(e, "UPI");
            }}
            disabled={loader}
          >
            PAY VIA UPI
            {loader && mode === "UPI" && (
              <div className="ms-2 spinner-grow spinner-grow-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductInfoFinalPopup;
