import React, { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import { Link } from "react-router-dom";
import Games from "../components/Games";
import CancelIcon from "@mui/icons-material/Cancel";
import "./Home.css";
import NavigationButtons from "../components/NavigationButtons";
import GamesForMobile from "../components/GamesForMobile";
import Header from "../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";

import {
  setShowAccountModal,
  setShowSupportModal,
} from "../redux/features/footerMenuSlice";
import HomePageMobileAlert from "./HomePageMobileAlert";
import { setShowPopup } from "../redux/features/alertSlice";
import LatestEventPopupModal from "../components/Home/LatestEventPopupModal";
import FeaturedBlog from "../components/Home/FeaturedBlog";

const Home = () => {
  const dispatch = useDispatch();
  const { showPopup } = useSelector((state) => state.alerts);
  const { notification } = useSelector((state) => state.data);

  useEffect(() => {
    const savedActiveButton = localStorage.getItem("activeButton");
    if (savedActiveButton === "account") {
      dispatch(setShowAccountModal(true));
    } else if (savedActiveButton === "support") {
      dispatch(setShowSupportModal(true));
    }
  }, [dispatch]);

  const handleClosePopup = () => {
    dispatch(setShowPopup(false)); 
    localStorage.setItem("giveaway", "false");
  };

  return (
    <Layout>
      {(showPopup && notification?.display === "yes") && (
        <div className="popup-container d-none d-lg-flex d-md-flex">
          <div>
            <CancelIcon onClick={handleClosePopup} className="icon" />
          </div>
          <Link target="_blank" to={notification?.link}>
            <img src={notification?.image} alt="popup-img" loading="lazy"/>
          </Link>
        </div>
      )}
      {(showPopup && notification?.display === "yes") && <HomePageMobileAlert popupData={notification} onClose={handleClosePopup}/>}
      <div className="d-block d-lg-none d-md-none">
        <Header />
      </div>
      <HeroSection/>
      <NavigationButtons />
      <div className="d-none d-lg-block d-md-block desktop-games-container">
        <Games title={"Popular Games Top-Up"} />
      </div>
      <div className="d-lg-none d-md-none">
        <GamesForMobile title={"Popular Games Top-Up"} />
      </div>
      <FeaturedBlog />
      <LatestEventPopupModal/> {/* popup */}
    </Layout>
  );
};

export default Home;
