import React from 'react';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const RedeemCodeToWallet = ({walletHistory, openModal, copyToClipboard}) => {
  return (
    <div className="user-wallet-content w-100">
        <p className='mb-0 border bg-light user-wallet-content-heading default-font'>Redeem Code</p>
        <div className='d-flex justify-content-start align-items-start gap-3 w-100'>
            <div className='user-wallet-transaction-content mt-1 w-100 px-3 py-5 center'>
                <div className='default-font center'>Coming Soon</div>
            </div>
        </div>
    </div>
  )
}

export default RedeemCodeToWallet
