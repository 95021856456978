import React, { useEffect, useState } from "react";
import { IoMdLogIn } from "react-icons/io";
import { FaBell } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AccountModal from "./AccountModal";
import NotLoginPopupModal from "../Modal/NotLoginPopupModal";
import {
  setActiveButton,
  setShowAccountModal,
  setShowSearchModal,
} from "../../redux/features/footerMenuSlice";

const MobileHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showAccountModal, showSearchModal } = useSelector((state) => state.footerMenu);
  const { user } = useSelector((state) => state.user);
  const { balance } = useSelector((state) => state.data);
  const [showModal, setShowModal] = useState(false);

  const getFirstLetter = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase();
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";

    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const handlePriceClick = () => {
    if (!user) {
      setShowModal(true);
    } else {
      navigate('/user-wallet')
    }
  };

  const onAccountClick = () => {
    navigate("/");
    dispatch(setActiveButton("account"));
    dispatch(setShowAccountModal(true));
  };

  return (
    <>
      <header className="h-100 w-100 header-mobile d-flex d-md-none d-lg-none justify-content-between align items-center py-3 px-3">
        <div className="" onClick={handlePriceClick}>
          {user? <div className="d-flex justify-content-start flex-column align-items-start">
            <span className="text-sm header-mobile-user">
              {capitalizeFirstLetter(user?.fname)}
            </span>
            <div className="center" style={{ marginLeft: "-2px" }}>
              <FaRupeeSign className="header-mobile-price-icon text-white" />
              <div
                className="header-mobile-price center"
              >
                {(balance && user)? parseFloat(balance).toFixed(2) : parseFloat('0').toFixed(2)}
              </div>
            </div>
          </div>
          :
          <div
            className="login-box sidebar-menu-section-2-icon color-1 center shadow pointer px-3"
            onClick={() => {
              user ? onAccountClick() : navigate("/login");
            }}
          >
            <span
              className={`fs-4 text-white center gap-2`}
            >
              <IoMdLogIn />
              <Link className="text-white fs-6 center" rel="stylesheet" href="/login" style={{fontWeight: '900'}}>
                <small>LOGIN</small>
              </Link>
            </span>
          </div>
          }
        </div>
        <div className="center header-mobile-content-2">
          <div
            className="rounded-box bg-search-color  center shadow pointer"
            onClick={() => dispatch(setShowSearchModal(!showSearchModal))}
          >
            <span className="center">
              <FaSearch className="header-mobile-search-icon" />
              {/* NotificationsIcon */}
            </span>
          </div>
          <div
            className="rounded-box bg-color center shadow pointer"
            onClick={() => {
              user ? onAccountClick() : navigate("/announcement");
            }}
          >
            <span
              className={`${
                user ? "header-mobile-text" : "fs-5"
              } text-white center`}
            >
              {user ? getFirstLetter(user?.fname)
              :
              <FaBell />
              }
            </span>
          </div>
        </div>
      </header>
      <AccountModal show={showAccountModal} user={user} />{" "}
      {/* Render the modal */}
      <NotLoginPopupModal
        string="Please login to see your account details."
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default MobileHeader;
