import React, { useState } from 'react';
import './PurchaseInvoice.css';
import logo from "../../img/logo3.png"

const PurchaseInvoice = ({ singlePurchase, handleLinkClick, getStatusClass, invoiceRef }) => {
  return (
    <>
    <div  ref={invoiceRef}  className='h-100 overflow-scroll w-100'>
        <div className="purchase-invoice-container">
            <div className='purchase-invoice-business-content p-3'>
                <div className='business-info text-left w-100'>
                    <h1 className='mb-3'>Invoice</h1>
                    <img width={70} src={logo} alt="" loading="lazy"/>
                </div>
                <div className='business-details text-end w-100'>
                    <h2>TOPUPPLAYGROUND</h2>
                    <p>Islampura, Near Haseen Farhan</p>
                    <p>MH, Bhiwandi 421302</p>
                    <p>contact@topupplayground.com</p>
                    <p>topupplayground.com</p>
                    <p>Ph: +917350712254</p>
                </div>
            </div>
            {/* Invoice Details */}
            <div className="invoice-details p-3 pb-2">
                <p><strong>Order ID:</strong> {singlePurchase?.orderId}</p>
                <p><strong>Date of purchase:</strong> {new Date(singlePurchase?.createdAt).toLocaleString()}</p>
            </div>
            {/* Customer and Purchase Details */}
            <div className='table-container p-2'>
                <table className="invoice-table">
                    <thead>
                    <tr>
                        <th>Item</th>
                        <th>Details</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><strong>Product</strong></td>
                        <td>{singlePurchase?.productinfo}</td>
                    </tr>
                    <tr>
                        <td><strong>Amount</strong></td>
                        <td>₹{parseFloat(singlePurchase?.amount).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td><strong>Quantity</strong></td>
                        <td>{singlePurchase?.orderDetails}</td>
                    </tr>
                    <tr>
                        <td><strong>Status</strong></td>
                        <td className={`text-${getStatusClass((singlePurchase?.status)?.toLowerCase())}`}>
                        {singlePurchase?.status}
                        </td>
                    </tr>
                    <tr>
                        <td><strong>Mode</strong></td>
                        <td>{singlePurchase?.paymentMode}</td>
                    </tr>
                    <tr>
                        <td><strong>User ID</strong></td>
                        <td>
                        {singlePurchase?.userId ? (
                            <a href="#" onClick={() => handleLinkClick(singlePurchase?.userId)} className="user-link">
                            {singlePurchase?.userId}
                            </a>
                        ) : 'N/A'}
                        </td>
                    </tr>
                    <tr>
                        <td><strong>Zone ID</strong></td>
                        <td>{singlePurchase?.zoneId || 'N/A'}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            {/* Additional Notes or Terms */}
            <div className="additional-notes p-2">
                <h4>Thank you for your purchase</h4>
                <p className="mb-0">
                    If you have any questions about this invoice, please contact us at <a href="mailto:contact@topupplayground.com" className="contact-link">contact@topupplayground.com</a>.
                </p>
            </div>
            {/* Additional Notes or Terms */}
            <div className="policy-links center gap-2 p-3">
                <a href="/privacy-policy" className="">Privacy Policy</a>
                <a href="/refund-policy" className="">Refund Policy</a>
                <a href="/terms" className="">Terms and Conditions</a>
                <a href="/contact" className="">Contact</a>
            </div>
        </div>
    </div>
    </>
  );
};

export default PurchaseInvoice;
