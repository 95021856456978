import React from "react";
import Layout from "../components/Layout/Layout";
import "./GiveAway.css";
import IMAGES from "../img/image";

const GiveAway = () => {
  return (
    <Layout>
      <div className="give-away-cont">
        <div className="g-img">
          <img src={IMAGES.giveaway} alt="" loading="lazy"/>
        </div>
        <div className="g-content">
          <h2>Give Away for April Month</h2>
          <h5>Complete below 3 steps</h5>
          <ul>
            <li>
              - Follow our Instagram page - <span>@topupplayground</span>
            </li>
            <li>
              - Make any order on our website -{" "}
              <span>www.topupplayground.com</span>
            </li>
            <li>
              - Refer a friend and send a screenshot on instagram -
              <span>@topupplayground</span>
            </li>
          </ul>
          <h2 className="mt-5">Winners</h2>
          <ul>
            <li>- 5 Lucky winners will get exciting gifts</li>
            <li>
              - 1st Winner will get a Netflix account and 500rs wallet balance
              in our website
            </li>
            <li>
              - 2nd Winner will get a prime video account and 400rs wallet
              balance in our website
            </li>
            <li>
              - 3nd Winner will get a prime video account and 300rs wallet
              balance in our website
            </li>
            <li>- 4th Winner will get a 200rs wallet balance in our website</li>
            <li>- 5th Winner will get a 100rs wallet balance in our website</li>
          </ul>
          <h2 className="mt-5">Important Notes</h2>
          <ul>
            <li>
              - We will make a list of users who has been participated and put
              the list in our system to find 5 random users who has done all the
              three steps.
            </li>
            <li>
              - All the three steps must be compulsory to be a winner. If a user
              who gets selected in our list he/she has not completed the three
              steps then the reward will not be given.
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default GiveAway;
