import React, { useState, useEffect } from 'react';
import './MemoryMatch.css';

const MemoryMatch = ({onBack}) => {
  const initialCards = [
    { id: 1, value: 1, matched: false },
    { id: 2, value: 1, matched: false },
    { id: 3, value: 2, matched: false },
    { id: 4, value: 2, matched: false },
    { id: 5, value: 3, matched: false },
    { id: 6, value: 3, matched: false },
    { id: 7, value: 4, matched: false },
    { id: 8, value: 4, matched: false },
  ];

  const [cards, setCards] = useState(shuffleCards([...initialCards]));
  const [selectedCards, setSelectedCards] = useState([]);
  const [matches, setMatches] = useState(0);

  // Shuffle cards at the start of the game
  function shuffleCards(cards) {
    return cards.sort(() => Math.random() - 0.5);
  }

  const handleCardClick = (index) => {
    if (selectedCards.length === 2 || cards[index].matched || selectedCards.includes(index)) return;
    setSelectedCards((prev) => [...prev, index]);

    if (selectedCards.length === 1) {
      const firstIndex = selectedCards[0];
      const secondIndex = index;

      if (cards[firstIndex].value === cards[secondIndex].value) {
        const updatedCards = cards.map((card, i) => ({
          ...card,
          matched: i === firstIndex || i === secondIndex ? true : card.matched,
        }));
        setCards(updatedCards);
        setMatches(matches + 1);
      }
      
      setTimeout(() => setSelectedCards([]), 1000);
    }
  };

  const resetGame = () => {
    setCards(shuffleCards([...initialCards]));
    setSelectedCards([]);
    setMatches(0);
  };

  return (
    <>
    <div className="memory-match mt-4">
      <h3>Memory Match Game</h3>
      <div className="board">
        {cards.map((card, index) => (
          <button
          key={index}
          className={`card ${selectedCards.includes(index) || card.matched ? 'flipped' : ''}`}
          onClick={() => handleCardClick(index)}
          >
            {selectedCards.includes(index) || card.matched ? card.value : "?"}
          </button>
        ))}
      </div>
      <div className="status">
        Matches: {matches}/{initialCards.length / 2}
      </div>
      <button className="reset" onClick={resetGame}>Reset Game</button>
    </div>
    <button className="memory-match-back-button" onClick={onBack}>
      Back to Maintenance
    </button>
    </>
  );
};

export default MemoryMatch;
