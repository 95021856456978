import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import "./BlogList.css";
import HeroSvg from '../components/Layout/HeroSvg';
import WhyTopUpPlayground from '../components/WhyTopUpPlayground';
import FooterMenu from '../components/Footer/FooterMenu';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import MobileHeader from '../components/Header/MobileHeader';
import SideMenu from '../components/Header/SideMenu';

const BlogList = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [sideMenu, setSideMenu] = useState(false);

  useEffect(() => {
    axios.get('/api/blogs')
      .then(response => setBlogs(response.data))
      .catch(error => console.error(error));
  }, []);

  return (
    <React.Fragment>
      <div className="home-page-background pb-lg-0 pb-md-0 pb-5">
        <HeroSvg/>
        {/* <div className="py-4 bg-warning">

        </div> */}
        <div className="d-none d-lg-block d-md-block">
          <Header />
        </div>

        <div className="d-block d-lg-none d-md-none">
          <div className="account-modal-container-content">
            <div className="top-left-content">
              <div className="box-1 center shadow" onClick={()=>{navigate('/')}}>
                <svg
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                </svg>
              </div>
              <h2 className="mb-0">All Blogs</h2>
            </div>
            <div
              className="top-right-content box-2 center"
              onClick={() => setSideMenu(!sideMenu)}
            >
              <svg
                width="18"
                height="18"
                fill="currentColor"
                className="bi bi-list text-white"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                />
              </svg>
            </div>
          </div>
        </div>
        
        <div className='container px-0 '>
          <div className='my-lg-5 mb-5'>
            <div className="blog-list px-3 px-lg-3">
              {blogs.map(blog => (
                <div key={blog.title} className="blog-card" onClick={()=>{navigate(`/blog/${blog.title}`)}}>
                  <img src={blog.image} alt={blog.title} />
                  <h2 className='mb-0'>{blog.title}</h2>
                  <p>{blog.category}</p>
                  <p className='blog-description'>{blog.description}</p>
                  <Link to={`/blog/${blog.title}`}>Read More</Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
        <div className="d-block">
          <WhyTopUpPlayground />
          <Footer />
        </div>
        <FooterMenu/>
      </div>
    </React.Fragment>
  );
};

export default BlogList;