import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import "./BlogDetail.css";
import WhyTopUpPlayground from '../components/WhyTopUpPlayground';
import FooterMenu from '../components/Footer/FooterMenu';
import MobileHeader from '../components/Header/MobileHeader';
import HeroSvg from '../components/Layout/HeroSvg';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import SideMenu from '../components/Header/SideMenu';
import Loader from './Loader';

const BlogDetail = () => {
  const navigate = useNavigate();
  const { blogTitle } = useParams();
  const [blog, setBlog] = useState(null);
  const [allBlogs, setAllBlogs] = useState([]);
  const [recentPosts, setRecentPosts] = useState([]);
  const [similarPosts, setSimilarPosts] = useState([]);
  const [sideMenu, setSideMenu] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/blogs');
        setAllBlogs(response.data);
        filterPosts(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();

    setLoading(true);
    axios.post(`/api/blogs/post`, { title: blogTitle })
      .then(response => {
        setBlog(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [blogTitle]);

  useEffect(() => {
    if (blog) {
      filterPosts(allBlogs);
    }
  }, [blog, allBlogs]);

  const filterPosts = (data) => {
    const recent = data?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0, 5);
    setRecentPosts(recent);

    const similar = data?.filter(b => b.category === blog.category && b.title !== blog.title);
    setSimilarPosts(similar);
  };

  if(!blog && loading){
    return <Loader />
  }

  const formatText = (text) => {
    return text?.split("\n")?.map((line, index) => {
        // Check if line contains a question mark and is the first in a new line
        if (line.includes("?")) {
          return `<strong>${line}</strong><br/>`; // Make it bold and add a break
        }
        return `${line}<br/>`; // Add break for other lines
      })
      .join(""); // Join all lines back into a single string
  };

  return (
    <React.Fragment>
      <div className="home-page-background pb-lg-0 pb-md-0 pb-5">
        <HeroSvg />
        <div className="d-none d-lg-block d-md-block">
          <Header />
        </div>
        <div className="d-block d-lg-none d-md-none">
          <div className="account-modal-container-content">
            <div className="top-left-content">
              <div className="box-1 center" onClick={() => { navigate('/blog') }}>
                <svg
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                </svg>
              </div>
              <h2 className="mb-0">Blog Details</h2>
            </div>
            <div
              className="top-right-content box-2 center"
              onClick={() => setSideMenu(!sideMenu)}
            >
              <svg
                width="18"
                height="18"
                fill="currentColor"
                className="bi bi-list text-white"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className='blog-detail'>
          <div className="blog-detail-hero-section" style={{ backgroundImage: `url(${blog?.image})` }}>
            <h1 className='container'>{blog?.title}</h1>
            <p>{blog?.category}</p>
          </div>
          <div className="blog-detail-content-container container my-4">
            <div className="blog-detail-content-section">
              <h1>{blog?.title}</h1>
              <hr />
              <p
                className="blog-description"
                dangerouslySetInnerHTML={{ __html: formatText(blog?.description) }}
              ></p>
              <hr />
              <h2>Content</h2>
              <hr />
              <p
                className="blog-description"
                dangerouslySetInnerHTML={{ __html: formatText(blog?.content) }}
              ></p>
            </div>
            <div className="blog-detail-sidebar-section">
              <h3>Recent Posts</h3>
              <ul className="post-list">
                {recentPosts.length !== 0 && recentPosts.map((post) => (
                  <li key={post._id} className='bg-white shadow-sm rounded p-2 d-flex gap-2'>
                    <img src={post.image} alt="" />
                    <a href={`/blog/${post.title}`} className="post-link">{post.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="blog-detail-content-container container my-4">
            <div className='blog-detail-similar-posts'>
              <h3>Similar Posts</h3>
              <ul className="similar-post-list">
                {similarPosts.length !== 0 &&  similarPosts.slice(0, 5).map((post) => ( // Limit to 5 posts
                  <li key={post._id} className='similar-post-card' onClick={()=>{window.location.href = `/blog/${post.title}`}}>
                    <img src={post.image} alt={post.title} />
                    <div className="post-details">
                      <a href={`/blog/${post.title}`} className="post-link">{post.title}</a>
                      <p className="post-description">{post.content}</p> {/* Add description */}
                      <div className='text-end'>
                        <a className='fs-6' href={`/blog/${post.title}`}>Read More</a>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
        <div className="d-block">
          <WhyTopUpPlayground />
          <Footer />
        </div>
        <FooterMenu />
      </div>
    </React.Fragment>
  );
};

export default BlogDetail;
