import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import "./AddBlog.css";

const AddRewardList = () => {
    const [rewardList, setRewardList] = useState(null);
    const [reward, setReward] = useState('');
    const [position, setPosition] = useState('');
    const [loading, setLoading] = useState(false);

    // Fetch the reward list
    const getRewardList = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/api/rewards/get-reward-list', {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                }
            });
            if (response.data.success) {
                setRewardList(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching reward list:', error);
            message.error("Error fetching reward list");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getRewardList();
        // eslint-disable-next-line
    }, []);

    // Handle form submission for adding a reward
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch('/api/rewards/add-reward-list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify({ reward, position: position ? Number(position) : null }),
            });

            const data = await response.json(); // Parse response to JSON

            if (data.success) {
                message.success(data.message);
                getRewardList(); // Refresh the reward list
                setReward(''); // Reset form fields
                setPosition('');
            } else {
                message.error("Failed to add reward");
            }
        } catch (error) {
            console.error('Error adding reward:', error);
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Handle reward deletion
    const handleDelete = async (rewardId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete?");
        if (confirmDelete) {
            setLoading(true);
            try {
                const response = await axios.delete(`/api/rewards/delete-reward-list/${rewardId}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                if (response.data.success) {
                    message.success("Reward deleted successfully.");
                    getRewardList(); // Refresh the reward list
                } else {
                    message.error("Failed to delete reward.");
                }
            } catch (error) {
                console.error("Error deleting reward:", error);
                message.error("An error occurred while deleting the reward.");
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <AdminLayout>
            <div className="admin-users-container">
                <div className="page-title mb-3">
                    <h3 className="m-0">Add Reward List</h3>
                </div>
                {!loading ? (
                    <div className="bg-light p-3 add-blog">
                        <div>
                            <form className="row p-0" onSubmit={handleSubmit}>
                                <div className="col-12 col-lg-4">
                                    <input
                                        type="text"
                                        placeholder="Reward"
                                        value={reward}
                                        onChange={(e) => setReward(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-12 col-lg-4">
                                    <input
                                        type="number"
                                        value={position}
                                        onChange={(e) => setPosition(e.target.value)}
                                        placeholder="Position"
                                    />
                                </div>
                                <div className="col-12 col-lg-4">
                                    <button type="submit">Add Reward</button>
                                </div>
                            </form>
                        </div>

                        {/* Display reward list in a table */}
                        {rewardList && (
                            <table className="table table-border mt-4 mx-0 w-100">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Reward</th>
                                        <th>Position</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rewardList.map((reward, index) => (
                                        <tr key={reward._id}>
                                            <td>{index + 1}</td>
                                            <td>{reward.reward}</td>
                                            <td>{reward.position}</td>
                                            <td className="d-flex gap-2">
                                                <button className="delete-btn" onClick={() => handleDelete(reward._id)}>
                                                    <DeleteIcon />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                ) : (
                    <div className="text-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                )}
            </div>
        </AdminLayout>
    );
};

export default AddRewardList;
