import React, { useEffect, useRef, useState } from 'react';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { submitPaymentForm } from '../../../pages/ProductInfoMobile/submitPaymentForm';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import "./AddMoneyToWallet.css";
import axios from 'axios';

const AddMoneyToWallet = ({walletHistory, openModal, copyToClipboard}) => {

  const { user } = useSelector((state) => state.user);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [gateway, setGateway] = useState("BARCODE");

  // Create a ref for the input field
  const amountInputRef = useRef(null);

  const handleOnChange = (e) => {
    setAmount(e.target.value);
    if (e.target.value !== "0" && e.target.value.trim() !== "") {
      setError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const amountNumber = Number(amount);
    if (isNaN(amountNumber)) {
      return message.error("Please enter amount in number");
    }
    if (amount === "" || amount === "0") {
      return setError(true);
    } else {
      if (gateway === "BARCODE") {
        handleBarcode();
      } else if (gateway === "PAYU") {
        handlePayu();
      }
    }
  };


  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
  
    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    amountInputRef.current.focus();
    // eslint-disable-next-line
  }, []);


  async function handlePayu() {
    setLoading(true);
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: amount,
        product_name: user?.sponsor || "noSponsor",
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        success_URL: "https://topupplayground.com/api/wallet/success",
        failure_URL: "https://topupplayground.com/api/wallet/failure",
        // success_URL: "http://localhost:8080/api/wallet/success",
        // failure_URL: "http://localhost:8080/api/wallet/failure",
        udf1: "",
        udf2: "",
        udf3: "",
        udf4: "",
        udf5: "",
      };

      setTimeout(() => {
        submitPaymentForm(paymentObject, orderId, setLoading);
      }, 1500);
    } catch (error) {
      console.error("Error initiating payment:", error);
    } finally{
      setLoading(false);
    }
  }

  async function handleBarcode() {
    if (amount < 1) {
      return message.error("Enter amount greater than 20");
    }
    setLoading(true);
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: amount,
        txn_note: "Wallet Topup Note",
        product_name: user?.sponsor || "noSponsor",
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://topupplayground.com/api/wallet/status?orderId=${orderId}`,
      };
      const response = await axios.post("/api/wallet/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        console.log(response.data.message);
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  }
  return (
    <div className="user-wallet-content w-100">
        <p className='mb-0 border bg-light user-wallet-content-heading default-font'>Add Money to Wallet</p>
        <div className='d-flex justify-content-start align-items-start gap-3 w-100'>
            <div className='user-wallet-transaction-content mt-1 w-100 px-3 py-4 center'>

              <div className='add-money-to-wallet'>
                <form
                  className="content mt-0 p-0"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <div className="form-custom">
                    <input
                      type="number"
                      autoComplete="off"
                      name="amount"
                      placeholder="Your amount"
                      id="amount"
                      className={`w-100 form-control ${error && "is-invalid"}`}
                      value={amount}
                      onChange={handleOnChange}
                      ref={amountInputRef} // Attach ref to the input
                    />
                    {error && (
                      <small className="text-danger">Please enter an amount</small>
                    )}
                  </div>
                  {!loading?
                    <button
                      className="btn-full shadow-bg shadow-bg-s w-100"
                      type="submit"
                    >
                        CONTINUE
                        {/* Wallet is Temporarily Disabled */}
                    </button>
                    :
                    <button disabled className="btn-full shadow-bg shadow-bg-s w-100">
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="visually-hidden"></span>
                      </div>
                    </button>
                  }
                </form>
              </div>

            </div>
        </div>
    </div>
  )
}

export default AddMoneyToWallet
