import React, { useState } from "react";
import "./UpdateMobileNumberModal.css";
import { message } from "antd";
import axios from "axios";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AsteriskSvg, CallSvg } from "../AllSvgModulesExport/LoginSvg";
import { IoClose } from "react-icons/io5";
import HeroSvg from "../Layout/HeroSvg";
import getUserData from "../../utils/userDataService";
import { setActiveButton } from "../../redux/features/footerMenuSlice";

const UpdateMobileNumberModal = ({ handleClose, path }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    mobile: "",
  });
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [otp, setOtp] = useState(null);
  const [userEnteredOtp, setUserEnteredOtp] = useState("");
  const [errors, setErrors] = useState({
    mobile: false,
    otp: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  const handleMobileOTPChange = (e) => {
    const { value } = e.target;
    setUserEnteredOtp(value);
    setErrors((prevErrors) => ({ ...prevErrors, otp: false }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { mobile } = form;

    const newErrors = {
      mobile: !mobile,
    };
    setErrors(newErrors);

    if (Object.values(newErrors).includes(true)) {
      return;
    }

    if (form?.mobile?.length !== 10) {
      message.error("Enter a 10-digit Mobile Number only");
      return;
    }

    setLoading(true);

    try {
      const res = await axios.post("/api/user/send-mobile-otp", form);
      if (res.data.success) {
        setTab(1);

        const {
          otp: encryptedOTP,
          key: encryptedKey,
          iv: encryptedIv,
        } = res.data.data;

        const key = CryptoJS.enc.Hex.parse(encryptedKey);
        const iv = CryptoJS.enc.Hex.parse(encryptedIv);

        // MOBILE OTP
        const decryptedOTP = CryptoJS.AES.decrypt(
          { ciphertext: CryptoJS.enc.Hex.parse(encryptedOTP) },
          key,
          { iv: iv }
        ).toString(CryptoJS.enc.Utf8);
        setOtp(decryptedOTP);
        console.log(decryptedOTP);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      message.error("Registration failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (isNaN(userEnteredOtp)) {
      return message.error("Enter only numbers");
    }

    if (userEnteredOtp !== otp) {
      setErrors((prevErrors) => ({ ...prevErrors, otp: true }));
      message.error("Invalid OTP. Please try again.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "/api/user/update-mobile-number",
        form,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success) {
        message.success(response.data.message);
        navigate(path);
        getUserData(dispatch);
        handleClose();
        if (path === "/") {
          dispatch(setActiveButton("home"));
        }
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="update-mobile-number-modal-container">
      <div className="update-mobile-number-modal-body h-100 text-end ">
        <button className="btn fs-3 border rounded-3" onClick={handleClose}>
          <IoClose />
        </button>
        <div className="mobile-login-container mt-3">
          <div className="mobile-login-content shadow-sm border p-3 text-start">
            {tab === 0 && (
              <form className="content" autoComplete="off">
                <h1 className="mb-0 pt-2">Add your mobile number!!</h1>
                <p>Fill up the form</p>

                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                  <div className="form-input">
                    <input
                      autoComplete="off"
                      value={form.mobile}
                      name="mobile"
                      type="text"
                      placeholder="Mobile"
                      id="mobile"
                      className={`form-control text-muted rounded-xs ${
                        errors.mobile && "is-invalid"
                      }`}
                      onChange={handleChange}
                    />
                    <CallSvg />
                    <div className="invalid-feedback">
                      Please enter a valid mobile number
                    </div>
                  </div>
                </div>

                <button
                  className="mobile-login-button mt-3"
                  type="submit"
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "SEND OTP"
                  )}
                </button>
              </form>
            )}

            {tab === 1 && (
              <form className="content" autoComplete="off">
                <h1 className="mb-0 pt-2">Verification !!</h1>
                <p>Enter Mobile OTP</p>

                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                  <div className="form-input">
                    <input
                      autoComplete="off"
                      name="mobile-otp"
                      value={userEnteredOtp}
                      type="text"
                      placeholder="Enter OTP"
                      id="mobile-otp"
                      className={`form-control text-muted rounded-xs ${
                        errors.otp && "is-invalid"
                      }`}
                      onChange={handleMobileOTPChange}
                    />
                    <AsteriskSvg />
                    <div className="invalid-feedback">Please enter the OTP</div>
                  </div>
                </div>

                <button
                  className="mobile-login-button mt-3"
                  type="submit"
                  disabled={loading}
                  onClick={handleRegister}
                >
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Verify And Update"
                  )}
                </button>
              </form>
            )}
          </div>
          <span className="center mt-4">
            <small>
              <p className="mb-0 fw-bold">
                All Rights Reserved © 2024 | TOPUP PLAYGROUND
              </p>
              <p className="mb-0">
                <span>Website designed & developed by</span>
                <Link
                  target="_blank"
                  className="text-danger"
                  to="https://aashirdigital.com/"
                >
                  ~@aashirdigital
                </Link>
              </p>
            </small>
          </span>
        </div>
      </div>
    </div>
  );
};

export default UpdateMobileNumberModal;
