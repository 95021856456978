import React from 'react';
import './WalletTransactionModal.css';
import { AiOutlineClose } from 'react-icons/ai';
import { message } from 'antd';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const WalletTransactionModal = ({ visible, onClose, data }) => {
    const handleOverlayClick = (e) => {
        if (e.target.className === 'wallet-transaction-modal-overlay') {
            onClose();
        }
    };

    const copyToClipboard = (event, text) => {
        event.stopPropagation();
        navigator.clipboard
          .writeText(text)
          .then(() => {
            message.success("Wallet ID copied to clipboard!");
          })
          .catch((err) => {
            message.error("Failed to copy!");
            console.error("Failed to copy: ", err);
          });
    };

    if (!visible) return null;

    return (
        <div className="wallet-transaction-modal-overlay px-3" onClick={onClose}>
            <div className="wallet-transaction-modal" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h2>Transaction Details</h2>
                    <AiOutlineClose className="close-icon" onClick={onClose} />
                </div>
                <div className="modal-content">
                    <div className="modal-row">
                        <span className="label">Info:</span>
                        {!data?.admin && <span className='mb-0'>{data?.p_info === "noSponsor"? "Add Money" : data?.p_info}</span>}
                        {data?.admin && <span className='mb-0'>{data?.p_info} <span>(admin)</span></span>}
                    </div>
                    <div className="modal-row">
                        <span className="label center">Wallet ID:</span>
                        <span
                            className="d-flex justify-content-start align-items-center rounded gap-1 mt-1"
                            onClick={(event) => {
                            copyToClipboard(event, `Wallet Id: ${data?.orderId}`);
                            }}
                            style={{ display: 'inline-flex' }} // Add this line
                        >
                            <ContentCopyIcon className="fs-6 ticket-id" />
                            <span className="ticket-id">{data?.orderId || 'id unavailable'}</span> {/* Adjusted to use the dynamic ticketId */}
                        </span>
                    </div>
                    <div className="modal-row">
                        <span className="label">Price:</span>
                        {(data?.p_info === "Add Money" || data?.p_info === "noSponsor" || data?.p_info === "Refund" || data?.p_info === "Reward") ? 
                                                
                            <span >+ ₹{data?.price}</span>
                            :
                            <span >- ₹{data?.price}</span>
                        }
                    </div>
                    <div className="modal-row">
                        <span className="label">Balance After:</span>
                        <span>₹{data?.balanceAfter}</span>
                    </div>
                    <div className="modal-row">
                        <span className="label">Date:</span>
                        <span>{new Date(data?.created).toLocaleDateString()}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WalletTransactionModal;
