import React from "react";
import "./LatestEventPopupModal.css";
import { useDispatch, useSelector } from "react-redux";
import banner from "../../img/eventimage/3.webp"
import { setShowEventPopupModal } from "../../redux/features/alertSlice";

const LatestEventPopupModal = () => {
    const dispatch = useDispatch();
    const { showEventPopupModal } = useSelector((state) => state.alerts);

  const closeModal = (e) => {
    e.stopPropagation();
    dispatch(setShowEventPopupModal(false)); 
    localStorage.setItem("giveaway", "false");
  };


  return (
    <div
      className={`event-popup-modal-container d-lg-none d-md-none ${
        showEventPopupModal ? "active" : ""
      }`}
      onClick={closeModal}
    >
        <div
            className={`event-popup-modal-content ${showEventPopupModal ? "active" : ""} p-3`}
            onClick={(e) => e.stopPropagation()}
        >
            <div className="w-100">
                <div
                  className="d-flex justify-content-between align-items-center"
                  onClick={()=>{
                    window.location.href = "https://topupplayground.com/leaderboard"
                  }}
                >
                    <img src={banner} alt="" loading="lazy"/>
                </div>
                <div className="pb-3"></div>
                    <button
                        className="btn w-100 text-white"
                        type="submit"
                        onClick={closeModal}
                    >
                        CLOSE
                    </button>
                </div>
            </div>
    </div>
  );
};

export default LatestEventPopupModal;
