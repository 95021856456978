import React from 'react';
import "./ReviewUsTrustPilot.css";
import image from "../img/trustpilot.png"

const ReviewUsTrustPilot = () => {
    const handleClick = () => {
        window.open("https://www.trustpilot.com/review/topupplayground.com", "_blank");
    }
  return (
    <div className='d-block  ReviewUsTrustPilot-container ' onClick={handleClick}>
        <div className='p-4 py-5 center'>
            <div className="d-flex align-items-center text-white gap-2 text-white">
                <h2 className="mb-0">Review us on</h2>
                <img width={30} src={image} alt="" />
                <h2 className='mb-0 border-bottom border-white'>Trustpilot</h2>
            </div>
        </div>
    </div>
  );
}

export default ReviewUsTrustPilot;
