import React, { useEffect, useState } from "react";
import axios from "axios";
import "./GoogleSignIn.css";
import UpdateMobileNumberModal from "../../components/Modal/UpdateMobileNumberModal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import getUserData from "../../utils/userDataService";
import { setActiveButton } from "../../redux/features/footerMenuSlice";

const GoogleSignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { showPopupUpdateMobile } = useSelector((state) => state.updateMobileModal);
  const [showPopupUpdateMobile, setShowUpdateMobileModal] = useState(false);

  useEffect(() => {
    // Load the Google Identity Services SDK dynamically
    const loadGoogleIdentityServices = () => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        // Initialize the Google Identity Services SDK
        window.google.accounts.id.initialize({
          client_id: "253544261573-ormbglpj22pa4ru0hupilfhjvvablbo0.apps.googleusercontent.com",
          callback: handleSuccess, // Callback function on successful login
        });

        // Render the Google Sign-In button
        window.google.accounts.id.renderButton(
          document.getElementById("googleSignInButton"),
          { theme: "outline", size: "large" }
        );
      };
      script.onerror = () => {
        console.error("Failed to load Google Identity Services script");
      };
      document.body.appendChild(script);
    };

    loadGoogleIdentityServices();
  }, []);

  const handleSuccess = async (response) => {
    try {
      const { credential } = response; // The JWT token from Google Identity
      const res = await axios.post("/api/user/google-login", { token: credential });
      const { token } = res.data;
      if(res.data.success){
        localStorage.setItem("token", token);
        // Save the JWT token in local storage or cookie
        if(!res.data.mobile){
          setShowUpdateMobileModal(true);
        } else {
          navigate("/");
          getUserData(dispatch);
          dispatch(setActiveButton("home"));
        }
      }
    } catch (error) {
      console.error("Google login failed", error);
    }
  };

  const onClose = ()=>{
    setShowUpdateMobileModal(!showPopupUpdateMobile);
    navigate("/");
    getUserData(dispatch);
    dispatch(setActiveButton("home"));
  }
  return (
    <div className="text-center">
      <div id="googleSignInButton" style={{ width: "100%"}}></div>
      {showPopupUpdateMobile && <UpdateMobileNumberModal path="/" handleClose={onClose}/>}
    </div>
  )
};

export default GoogleSignIn;
