import React, { useEffect, useRef, useState } from "react";
import IMAGES from "../../img/image";
import Slider from "react-slick";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import axios from "axios";
import { message } from "antd";
import "./HeroSection.css";
import { Link } from "@mui/material";
import banner from "../../img/home/banner2.jpg";
import { useSelector } from "react-redux";
import HeroSvg from "../Layout/HeroSvg";

const HeroSection = () => {
  const arrowRef = useRef();
  const { banners } = useSelector((state) => state.data);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* Desktop and Tablet  */}
      <div className="hero-section-main-container">
        <div className=" hero-container d-none d-md-block d-lg-block container">
          <Slider ref={arrowRef} {...settings}>
            {banners &&
              banners?.map((item, index) => {
                return (
                  <div className="p-1">
                    <div className="p-3 pointer banner-img-container border bg-light shadow-sm" key={index} onClick={()=>{window.open(item.link, "_blank")}}>
                      <Link to={item?.link} target="_blank">
                        <img
                          src={`https://topupplayground.com/${item.image}`}
                          className="d-block w-100"
                          alt="banners"
                          loading="lazy"
                        />
                      </Link>
                    </div>
                  </div>
                );
              })}
          </Slider>
          <div className="hero-slider-btns">
            <div onClick={() => arrowRef.current.slickPrev()}>
              <KeyboardArrowLeftIcon className="icon arrow-left" />
            </div>
            <div onClick={() => arrowRef.current.slickNext()}>
              <KeyboardArrowRightIcon className="icon arrow-right" />
            </div>
          </div>
        </div>
        <div className="hero-section-mobile d-lg-none d-md-none mt-1">
          {banners?

            banners?.slice() // Create a shallow copy of banners to avoid modifying the original array
            .sort((a, b) => a.seq - b.seq).map((item, index) => (
            <div key={index} className="px-3 hero-section-mobile-img-container " onClick={()=>{window.open(item.link, "_blank")}}>
              <Link className="w-100" to={item?.link} target="_blank">
                <div className="position-relative">
                  <img
                    src={`https://topupplayground.com/${item.image}`}
                    className="d-block w-100"
                    alt="banners"
                    loading="lazy"
                  />
                  {/* Overlay element */}
                  <div className="image-overlay"></div>
                  <div className="item-name-container text-start ">
                      <div className="item-name-content">
                        <h2 className="text-white fs-4">{item?.heading}</h2>
                        <span className="mb-2">{item?.title}</span>
                      </div>
                  </div>
                </div>
              </Link>
            </div>
          ))
          :
          (
            <div className="mx-3 w-100 rounded-4 overflow-hidden position-relative">
              <Link to={"#"} target="_blank" className={`loading`}>
                <img src={""} className="d-block w-100" alt="banners" loading="lazy"/>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HeroSection;
