import React, { useState } from "react";
import "./MobileLogin.css";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SideMenu from "../../components/Header/SideMenu";
import HeroSvg from "../../components/Layout/HeroSvg";
import { setActiveButton } from "../../redux/features/footerMenuSlice";
import {
  ChevronLeftSvg,
  EmailSvg,
  ListSvg,
} from "../../components/AllSvgModulesExport/LoginSvg";
import { message } from "antd";
import axios from "axios";

const MobileForgotPass = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [sideMenu, setSideMenu] = useState(false);
  const [form, setForm] = useState({
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: false,
    otp: false,
    password: false,
    confirmPassword: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, otp, password, confirmPassword } = form;

    if (tab === 0 && !email) {
      setErrors({ email: true });
      return;
    }

    if (tab === 1 && !otp) {
      setErrors({ otp: true });
      return;
    }

    if (tab === 2) {
      if (!password || !confirmPassword) {
        setErrors({ password: !password, confirmPassword: !confirmPassword });
        return;
      }
      if (password !== confirmPassword) {
        setErrors({ password: true, confirmPassword: true });
        message.error("Passwords do not match");
        return;
      }
    }

    setLoading(true);
    try {
      if (tab === 0) {
        const res = await axios.post("/api/user/send-otp", {
          email,
          msg: "We got your back! For password reset OTP is",
        });
        if (res.data.success) {
          message.success(res.data.message);
          setTab(1);
        } else {
          message.error(res.data.message);
        }
      } else if (tab === 1) {
        const res = await axios.post("/api/user/verify-otp", {
          email: email,
          userEnteredOtp: otp,
        });
        if (res.data.success) {
          message.success("OTP verified, you can now reset your password");
          setTab(2);
        } else {
          message.error(res.data.message);
        }
      } else if (tab === 2) {
        const res = await axios.post("/api/user/update-pass", {
          email: email,
          pass: password,
        });
        if (res.data.success) {
          message.success("Password reset successfully");
          navigate("/login");
        } else {
          message.error(res.data.message);
        }
      }
    } catch (error) {
      message.error("Failed to process your request");
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    if (tab === 2) {
      setTab(1);
    } else if (tab === 1) {
      setTab(0);
    } else if (tab === 0) {
      dispatch(setActiveButton("home"));
      navigate("/");
    }
  };

  return (
    <>
      <div className="account-modal-container d-lg-none d-md-none">
        <HeroSvg />
        <div className="account-modal-container-content">
          <div className="top-left-content">
            <div className="box-1 center" onClick={onClose}>
              <ChevronLeftSvg />
            </div>
            <h2 className="mb-0">Forgot Password</h2>
          </div>
          <div
            className="top-right-content box-2 center"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <ListSvg />
          </div>
        </div>

        <div className="mobile-login-container px-3 mt-3">
          <div className="mobile-login-content px-3">
            {tab === 0 && (
              <form
                className="content"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <h1 className="mb-0 pt-2">Don't Worry !!</h1>
                <p>Fill up the form</p>
                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                  <div className="form-input">
                    <input
                      type="email"
                      autoComplete="off"
                      name="email"
                      placeholder="Your registered email"
                      id="email"
                      className={`form-control text-muted rounded-xs ${
                        errors.email && "is-invalid"
                      }`}
                      value={form.email}
                      onChange={handleChange}
                    />
                    <div className="invalid-feedback">
                      Please enter your email id
                    </div>
                    <EmailSvg />
                  </div>
                </div>

                <button className="mobile-login-button mt-3" type="submit">
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden"></span>
                    </div>
                  ) : (
                    "SEND OTP"
                  )}
                </button>
                <div className="mobile-login-form-footer-content mt-3 d-flex justify-content-between align-items-center px-2">
                  <p className="mb-0 text-ent">
                    Already registered? <Link to="/login">Login</Link>
                  </p>
                  <p className="mb-0 text-start">
                    New here? <Link to="/register">Register</Link>
                  </p>
                </div>
              </form>
            )}

            {tab === 1 && (
              <form
                className="content"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <h1 className="mb-0 pt-2">Verify Your OTP !!</h1>
                <p>Fill up the form</p>
                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                  <div className="form-input">
                    <input
                      type="text"
                      autoComplete="off"
                      name="otp"
                      placeholder="OTP"
                      id="otp"
                      className={`form-control text-muted rounded-xs ${
                        errors.otp && "is-invalid"
                      }`}
                      value={form.otp}
                      onChange={handleChange}
                    />
                    <div className="invalid-feedback">
                      Please enter the OTP sent to your email
                    </div>
                    <EmailSvg />
                  </div>
                </div>

                <button className="mobile-login-button mt-3" type="submit">
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden"></span>
                    </div>
                  ) : (
                    "VERIFY"
                  )}
                </button>
                <div className="mobile-login-form-footer-content mt-3 d-flex justify-content-between align-items-center px-2">
                  <p className="mb-0 text-ent">
                    Already registered? <Link to="/login">Login</Link>
                  </p>
                  <p className="mb-0 text-start">
                    New here? <Link to="/register">Register</Link>
                  </p>
                </div>
              </form>
            )}

            {tab === 2 && (
              <form
                className="content"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <h1 className="mb-0 pt-2">Set Your Password !!</h1>
                <p>Fill up the form</p>
                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                  <div className="form-input">
                    <input
                      type="password"
                      autoComplete="off"
                      name="password"
                      placeholder="Password"
                      id="password"
                      className={`form-control text-muted rounded-xs ${
                        errors.password && "is-invalid"
                      }`}
                      value={form.password}
                      onChange={handleChange}
                    />
                    <div className="invalid-feedback">
                      Please enter your password
                    </div>
                    <EmailSvg />
                  </div>
                </div>
                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                  <div className="form-input">
                    <input
                      type="password"
                      autoComplete="off"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      id="confirmPassword"
                      className={`form-control text-muted rounded-xs ${
                        errors.confirmPassword && "is-invalid"
                      }`}
                      value={form.confirmPassword}
                      onChange={handleChange}
                    />
                    <div className="invalid-feedback">
                      Please confirm your password
                    </div>
                    <EmailSvg />
                  </div>
                </div>

                <button className="mobile-login-button mt-3" type="submit">
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden"></span>
                    </div>
                  ) : (
                    "RESET PASSWORD"
                  )}
                </button>
                <div className="mobile-login-form-footer-content mt-3 d-flex justify-content-between align-items-center px-2">
                  <p className="mb-0 text-ent">
                    Already registered? <Link to="/login">Login</Link>
                  </p>
                  <p className="mb-0 text-start">
                    New here? <Link to="/register">Register</Link>
                  </p>
                </div>
              </form>
            )}
          </div>
          <span className="center mt-4">
            <small>
              <p className="mb-0 fw-bold">
                All Rights Reserved © 2024 | TOPUP PLAYGROUND
              </p>
              <p className="mb-0">
                <span>Website designed & developed by</span>
                <Link
                  target="_blank"
                  className="text-danger"
                  to="https://aashirdigital.com/"
                >
                  ~@aashirdigital
                </Link>
              </p>
            </small>
          </span>
        </div>
      </div>
      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  );
};

export default MobileForgotPass;
