import React from 'react'

const WalletButtons = ({setToggle, toggle}) => {
  return (
    <div className="mb-3 user-wallet-buttons-container">
        <div className='user-wallet-buttons-content'>
            <div className={`user-wallet-button ${toggle === "history" && 'active'}`} onClick={()=>{setToggle('history')}}>
                <svg fill="currentColor" className="bi bi-bag" viewBox="0 0 16 16" style={{ color: '#70b452'}}>
                    <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
                </svg>
                <h4>History</h4>
            </div>
            <div className={`user-wallet-button ${toggle === "add" && 'active'}`} onClick={()=>{setToggle('add')}}>
                <svg fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16" style={{ color: '#b46952'}}>
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                </svg>
                <h4>Add</h4>
            </div>
            <div className={`user-wallet-button ${toggle === "redeem" && 'active'}`} onClick={()=>{setToggle('redeem')}}>
                <svg fill="currentColor" className="bi bi-gift" viewBox="0 0 16 16" style={{ color: '#70b452'}}>
                    <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A3 3 0 0 1 3 2.506zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43zM9 3h2.932l.023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0zM1 4v2h6V4zm8 0v2h6V4zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5z"/>
                </svg>
                <h4>Redeem</h4>
            </div>
            <div className={`user-wallet-button ${toggle === "transfer" && 'active'}`} onClick={()=>{setToggle('transfer')}}>
                <svg fill="currentColor" className="bi bi-send text-primary" viewBox="0 0 16 16" style={{ color: '#000000'}}>
                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
                </svg>
                <h4>Transfer</h4>
            </div>
        </div>
    </div>
  )
}

export default WalletButtons
