import React, { useState } from "react";
import EngineeringIcon from "@mui/icons-material/Engineering";
import "./Maintenance2.css";
import MemoryMatch from "./MemoryMatch";
import axios from "axios";

const Maintenance = ({setWebsite}) => {
  // State to manage whether to show the game or the maintenance message
  const [showGame, setShowGame] = useState(false);

   
  async function getWebsite() {
    try {
      const res = await axios.get("/api/admin/get-website");
      if (res.data.success) {
        setWebsite(res.data.data.website);
      } 
    } catch (error) {
      console.log(error);
    }
  }

  // Function to handle game request
  const handlePlayGame = async () => {
    await getWebsite();
    setShowGame(true);
  };

  const handleOnBack = async ()=>{
    await getWebsite();
    setShowGame(false);
  }

  return (
    <div className="maintenance-page">
      {!showGame ? (
        <>
          <EngineeringIcon className="icon" />
          <h1 className="d-lg-none d-md-none d-block">Under Maintenance</h1>
          <h1 className="d-none d-lg-block d-md-block">Website Under Maintenance</h1>
          <p>We’ll be right back!</p>
          <p>Thank you for your patience.</p>
          <div className="loading-bar">
            <div className="loading-progress"></div>
          </div>
          {/* Play Game prompt below the loading bar */}
          <button className="play-game-button" onClick={handlePlayGame}>
            Want to play a game while you wait?
          </button>
        </>
      ) : (
        // Render the game component if the user clicks the button
        <MemoryMatch onBack={handleOnBack}/>
      )}
    </div>
  );
};

export default Maintenance;
