// PhotoUpload.js
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import "./PhotoUpload.css";
import IMAGES from "../img/image";

const PhotoUpload = ({ onImageChange }) => {
  const [image, setImage] = useState(null);
  const [editor, setEditor] = useState(null);
  const [editedImage, setEditedImage] = useState(null);
  const [zoom, setZoom] = useState(1);

  // Variable for adjusting the position of the camera in the avatar image
  const cameraPosition = {
    x: 20, // Adjust as needed
    y: 20, // Adjust as needed
  };

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setImage(file);
    }
  };

  const handleSave = () => {
    const editedImageDataUrl = editor.getImageScaledToCanvas().toDataURL();
    setEditedImage(editedImageDataUrl);
    onImageChange(editedImageDataUrl);
  };

  const handleZoomChange = (event) => {
    setZoom(parseFloat(event.target.value));
  };

  const handleDownload = () => {
    if (editedImage) {
      const img = new Image();
      img.src = editedImage;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const padding = 80;
        const imageSize = Math.max(img.width, img.height) + 2 * padding;

        canvas.width = imageSize;
        canvas.height = imageSize;

        ctx.fillStyle = "#eee";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const borderRadius = 20;
        const x = (canvas.width - img.width) / 2;
        const y = (canvas.height - img.height) / 2;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x + borderRadius, y);
        ctx.arcTo(
          x + img.width,
          y,
          x + img.width,
          y + img.height,
          borderRadius
        );
        ctx.arcTo(
          x + img.width,
          y + img.height,
          x,
          y + img.height,
          borderRadius
        );
        ctx.arcTo(x, y + img.height, x, y, borderRadius);
        ctx.arcTo(x, y, x + img.width, y, borderRadius);
        ctx.closePath();
        ctx.clip();
        ctx.drawImage(img, x, y, img.width, img.height);
        ctx.restore();

        const cameraImage = new Image();
        cameraImage.src = IMAGES.camera;
        cameraImage.onload = () => {
          const cameraSize = 100;
          const cameraX = x + cameraPosition.x;
          const cameraY = y + cameraPosition.y;
          ctx.drawImage(cameraImage, cameraX, cameraY, cameraSize, cameraSize);

          const desiredWidth = 500;
          const desiredHeight = (desiredWidth / canvas.width) * canvas.height;

          const resizedCanvas = document.createElement("canvas");
          resizedCanvas.width = desiredWidth;
          resizedCanvas.height = desiredHeight;
          resizedCanvas
            .getContext("2d")
            .drawImage(canvas, 0, 0, desiredWidth, desiredHeight);

          const downloadLink = document.createElement("a");
          downloadLink.href = resizedCanvas.toDataURL("image/jpeg", 0.8);
          downloadLink.download = "edited_image.jpg";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        };
      };
    }
  };

  return (
    <div className="photo-upload-container">
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} style={dropzoneStyle}>
            <input {...getInputProps()} />
            <p>Drag & drop an image here, or click to select one</p>
          </div>
        )}
      </Dropzone>

      <div className="image-preview-container">
        {image && (
          <>
            <div className="avatar-editor-container">
              <AvatarEditor
                ref={(editorRef) => setEditor(editorRef)}
                image={image}
                width={300}
                height={550}
                border={50}
                borderRadius={20}
                color={[255, 255, 255, 0.6]}
                scale={zoom}
              />
            </div>
          </>
        )}
        {editedImage && (
          <div className="edited-image-container">
            <div className="edited-image">
              <img
                src={editedImage}
                alt="Edited"
                loading="lazy"
                style={{
                  width: "60%",
                  height: "auto",
                  borderRadius: "20px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="preview-buttons">
        <label>Zoom:</label>
        <input
          type="range"
          min="1"
          max="3"
          step="0.1"
          value={zoom}
          onChange={handleZoomChange}
        />
        <button onClick={handleSave}>Save</button>
        <button onClick={handleDownload}>Download</button>
      </div>
    </div>
  );
};

const dropzoneStyle = {
  border: "2px dashed #ddd",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default PhotoUpload;
