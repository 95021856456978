import { createSlice } from "@reduxjs/toolkit";

export const updateMobileSlice = createSlice({
  name: "updateMobileModal", // Ensure the slice name is correct
  initialState: {
    loading: false,
    showPopupUpdateMobile: true, // Initialize showPopup as false
  },
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state) => {
      state.loading = false;
    },
    setShowUpdateMobileModal: (state, action) => {
      state.showPopupUpdateMobile = action.payload;
    },
  },
});

export const { showLoading, hideLoading, setShowUpdateMobileModal } = updateMobileSlice.actions;

// Make sure this is properly integrated into the store
