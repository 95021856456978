import React from 'react';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const WalletHistory = ({walletHistory, openModal, copyToClipboard}) => {
  return (
    <div className="user-wallet-content w-100">
        <p className='mb-0 border bg-light user-wallet-content-heading default-font'>Transactions History</p>
        <div className='d-flex justify-content-start align-items-start gap-3 w-100'>
            <div className='user-wallet-transaction-content mt-1 w-100'>
                <div className='user-wallet-transaction-history-data'>
                    {walletHistory? walletHistory?.map((data)=>{
                        return (
                            <div key={data?._id} className='user-wallet-transaction-history-data-card border-bottom w-100'>
                                <div className='center gap-2'>
                                    <div className={`user-wallet-transaction-history-add-or-remove-icon center ${(data?.p_info === "Add Money" || data?.p_info === "noSponsor" || data?.p_info === "Refund" || data?.p_info === "Reward") && 'active'}`}>
                                        {(data?.p_info === "Add Money" || data?.p_info === "noSponsor" || data?.p_info === "Refund" || data?.p_info === "Reward") ? '+' : '-'}
                                    </div>
                                    <div className='user-wallet-transaction-history-heading'>
                                        {!data?.admin && <h3 className='mb-0'>{data?.p_info === "noSponsor"? "Add Money" : data?.p_info}</h3>}
                                        {data?.admin && <h3 className='mb-0'>{data?.p_info} <small>(admin)</small></h3>}
                                        <span
                                            className="d-flex justify-content-start align-items-center rounded gap-1 mt-1"
                                            onClick={(event) => {
                                            copyToClipboard(event, `Wallet Id: ${data?.orderId}`);
                                            }}
                                            style={{ display: 'inline-flex' }} // Add this line
                                        >
                                            <ContentCopyIcon className="fs-6 icon" />
                                            <span className="">{data?.orderId || 'id unavailable'}</span> {/* Adjusted to use the dynamic ticketId */}
                                        </span>
                                    </div>
                                </div>
                                <div className='user-wallet-transaction-history-data-balance'>
                                    {(data?.p_info === "Add Money" || data?.p_info === "noSponsor" || data?.p_info === "Refund" || data?.p_info === "Reward") ? 
                                    
                                        <p className='text-success center py-1 border border-success fw-bold rounded-pill mb-0' onClick={()=>{openModal(data)}}>+ ₹{data?.price}</p>
                                        :
                                        <p className='text-danger center py-1 border border-danger fw-bold rounded-pill mb-0' onClick={()=>{openModal(data)}}>- ₹{data?.price}</p>
                                    }
                                    {/* <div className='mb-0'>Bal: {data?.balanceAfter}</div> */}
                                </div>
                            </div>
                        )
                    }) :
                    <div className='text-center py-5'>
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default WalletHistory
