import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../Footer/Footer.css";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useDispatch, useSelector } from "react-redux";
import { LinkSvg } from "../AllSvgModulesExport/LoginSvg";
import { setShowSupportModal } from "../../redux/features/footerMenuSlice";
import ReviewUsTrustPilot from "../ReviewUsTrustPilot";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);

  const handleContactUsClick = () => {
    const isMobile = window.innerWidth <= 768; // Mobile screen size threshold (768px)

    if (isMobile) {
      dispatch(setShowSupportModal(true));
    } else {
      navigate("/contact");
    }
  };
  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        <div className="container footer-container-content">
          <div className="col-lg-3 px-2 footer-content">
            <h4 className="fw-bold">Need help?</h4>
            <button
              className="bg-warning text-dark px-3 py-2 rounded-4 border-0 d-flex justify-content-between align-items-center gap-2"
              onClick={handleContactUsClick}
            >
              <LinkSvg />
              <span className="fw-bold">Contact us</span>
            </button>
          </div>
          <div className="col-lg-3 px-2 footer-content">
            <h4 className="fw-bold">Quick Links</h4>
            <div className="d-flex gap-2">
              <button
                className="bg-white px-3 py-2 rounded-4 border-0 d-flex justify-content-between align-items-center gap-2"
                onClick={() => {
                  navigate("/login");
                }}
              >
                <span className="text-dark fw-bold">Login</span>
                <LinkSvg />
              </button>
              <button
                className="bg-white px-3 py-2 rounded-4 border-0 d-flex justify-content-between align-items-center gap-2"
                onClick={() => {
                  navigate("/register");
                }}
              >
                <span className="text-dark fw-bold">Register</span>
                <LinkSvg />
              </button>
            </div>
          </div>
          <div className="col-lg-3 px-2 footer-content">
            <h4 className="fw-bold">Stay updated with us</h4>
            <div className="d-flex gap-2">
              <InstagramIcon
                className="social-icon rounded-pill p-2 bg-white text-dark"
                onClick={() => {
                  window.location.href =
                    "https://www.instagram.com/topup_playground/";
                }}
              />
              <WhatsAppIcon
                className="social-icon rounded-pill p-2 bg-white text-dark"
                onClick={() => {
                  window.location.href = "https://wa.me/917350712254";
                }}
              />
              <EmailIcon
                className="social-icon rounded-pill p-2 bg-white text-dark"
                onClick={() => {
                  window.location.href = "mailto:topupplaygroundofficial.com";
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ReviewUsTrustPilot />
      <div className="bg-light footer-container-bottom py-4 px-4">
        <div className="container footer-container-bottom-content mb-5 mb-lg-0">
          <div className="footer-container-bottom-data gap-2">
            <span className="copyright">
              © Copyright <span className="fw-bold">Topup Playground</span>
            </span>
            <span className="dot">•</span> {/* Dot separator */}
            <span className="copyright">
              <span>Developed by</span>
              <Link
                target="_blank"
                className="text-danger"
                to="https://aashirdigital.com/"
              >
                ~@aashirdigital
              </Link>
            </span>
            <span className="dot">•</span>
            <span className="copyright">
              <Link target="_blank" className="text-dark" to="/terms">
                Terms and Conditions
              </Link>
            </span>
            <span className="dot">•</span>
            <span className="copyright">
              <Link target="_blank" className="text-dark" to="/privacy-policy">
                Privary Policy
              </Link>
            </span>
            <span className="dot">•</span>
            <span className="copyright">
              <Link target="_blank" className="text-dark" to="/refund-policy">
                Refund Policy
              </Link>
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
