import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './RewardDetailsModal.css';
import { CloseSvg } from '../Header/AccountModalSvg';

const RewardDetailsModal = ({ isOpen, onClose, user, tab }) => {
    const [rewardList, setRewardList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userReward, setUserReward] = useState(null);

    const getRewardList = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/api/rewards/get-reward-list', {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            if (response.data.success) setRewardList(response.data.data);
        } catch (error) {
            console.error('Error fetching reward list:', error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    useEffect(() => {
        if (isOpen) getRewardList();
    }, [isOpen]);

    useEffect(() => {
        if (rewardList.length > 0 && user) {
            const reward = rewardList.find(r => r.position === user.position) || 
                           rewardList.find(r => r.position === null);
            setUserReward(reward);
        }
    }, [rewardList, user]);

    if (!isOpen) return null;

    return (
        <div className="reward-modal-overlay px-3" onClick={onClose}>
            <div className="reward-modal-content" onClick={e => e.stopPropagation()}>
                <div className="glowing-border">
                    <h2 className="reward-title">{user.name}</h2>
                    <p className="reward-info">Total Spent: ₹{user.totalSpent}</p>
                    {loading ? (
                        <p className="loading-text">Loading reward...</p>
                    ) : (
                        <>
                            <p className="reward-position">Position: {user.position}</p>
                            {userReward && (
                                <p className="reward-price fw-bold">Reward: {userReward.reward}</p>
                            )}
                            <div className='text-white'>
                                <strong className='me-1'>Note:</strong>
                                {tab === 1?
                                    <span>The reward will be given after the specified time ends, and the reward's position and price may change before the time runs out.</span>
                                    :
                                    <span>The reward has been delivered. Please check your wallet</span>
                                }
                            </div>
                        </>
                    )}
                    <button className="close-button center" onClick={onClose}>
                        <CloseSvg />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RewardDetailsModal;
