import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="game-controller">
        <div className="button top-left"></div>
        <div className="button top-right"></div>
        <div className="button bottom-left"></div>
        <div className="button bottom-right"></div>
      </div>
    </div>
  );
};

export default Loader;
