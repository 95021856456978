import React from 'react';
import './RewardPopupModal.css';
import { CloseSvg } from '../Header/AccountModalSvg';
import popupimage from "../../img/eventimage/leaderboardpopup.png";

const RewardPopupModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="reward-popup-modal-overlay" onClick={onClose}>
            <div className="reward-popup-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="reward-popup-modal-close-button" onClick={onClose}>
                    <CloseSvg />
                </button>
                <div className='image-container'>
                    <img src={popupimage} alt="Leaderboard Challenge" />
                </div>
            </div>
        </div>
    );
};

export default RewardPopupModal;
