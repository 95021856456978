import React, { useEffect, useRef, useState } from "react";
import "./Announcement.css";
import { Link, useNavigate } from "react-router-dom";
import HeroSvg from "../components/Layout/HeroSvg";
import SideMenu from "../components/Header/SideMenu";
import { useDispatch } from "react-redux";
import {
  setActiveButton,
  setShowAccountModal,
  setShowSupportModal,
} from "../redux/features/footerMenuSlice";

const Announcement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sideMenu, setSideMenu] = useState(false);

  const onClose = () => {
    navigate("/");
    dispatch(setActiveButton("home"));
    localStorage.setItem("activeButton", "home");
  };

  const accordionItems = [
    {
      id: "accordion5-1",
      icon: (
        <svg
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-chat-heart-fill text-danger"
          viewBox="0 0 16 16"
        >
          <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9 9 0 0 0 8 15m0-9.007c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132" />
        </svg>
      ),
      title: "Leave a Review",
      content: `We’ve made some exciting updates to Topup Playground to enhance the recharge process, making it faster and more seamless than ever. Your feedback is important to us, and we’d love to hear about your experience! Please take a moment to share your thoughts on Trustpilot: https://www.trustpilot.com/review/topupplayground.com`,
    },
    {
      id: "accordion5-2",
      icon: (
        <svg
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-chat-heart-fill text-danger"
          viewBox="0 0 16 16"
        >
          <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9 9 0 0 0 8 15m0-9.007c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132" />
        </svg>
      ),
      title: "All services are running seamlessly",
      content: `All services are now running smoothly. We’ve successfully resolved the Add Money issue along with several purchase-related problems.`,
    },
    // Add more items here as needed
  ];

  return (
    <>
      <div className="announcement-container d-lg-none d-md-none">
        <HeroSvg />
        <div className="account-modal-container-content">
          <div className="top-left-content">
            <div className="box-1 center" onClick={onClose}>
              <svg
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                />
              </svg>
            </div>
            <h2 className="mb-0">Announcements</h2>
          </div>
          <div
            className="top-right-content box-2 center"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <svg
              width="18"
              height="18"
              fill="currentColor"
              className="bi bi-list text-white"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
              />
            </svg>
          </div>
        </div>

        <div className="announcement-bottom-container px-3">
          <div className="announcement-bottom-content px-3 pb-3">
            <div className="card-bottom p-3">
              <h1 className="text-white mb-0">Announcements</h1>
              <p className="text-white mb-0 opacity-60">
                From our Topupplayground Team.
              </p>
            </div>
            <div className="card-overlay bg-gradient-fade"></div>
          </div>
        </div>

        <div className="announcement-bottom-container px-3">
          <div className="announcement-bottom-content-issue-facing p-3 py-0">
            <div className="content">
              <div className="accordion accordion-m" id="accordion-group-5">
                {accordionItems.map((item, index) => (
                  <div className="accordion-item " key={item.id}>
                    <button
                      className="accordion-button px-0 ps-1 gap-2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${item.id}`}
                      aria-expanded={index === 0 ? "true" : "false"}
                    >
                      <span className="center">{item.icon}</span>
                      <span className="font-600 font-14">{item.title}</span>
                    </button>
                    <div
                      id={item.id}
                      className={`accordion-collapse collapse ${
                        index === 0 ? "show" : ""
                      }`}
                      data-bs-parent="#accordion-group-5"
                    >
                      <p className="mb-0 pb-3">
                        {item.content
                          .split(
                            "https://www.trustpilot.com/review/topupplayground.com"
                          )
                          .map((part, i) => (
                            <React.Fragment key={i}>
                              {part}
                              {i <
                                item.content.split(
                                  "https://www.trustpilot.com/review/topupplayground.com"
                                ).length -
                                  1 && (
                                <a
                                  href="https://www.trustpilot.com/review/topupplayground.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://www.trustpilot.com/review/topupplayground.com
                                </a>
                              )}
                            </React.Fragment>
                          ))}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <span className="center mt-4">
          <small>
            <p className="mb-0 fw-bold">
              All Rights Reserved © 2024 | TOPUP PLAYGROUND
            </p>
            <p className="mb-0">
              <span>Website designed & developed by</span>
              <Link
                target="_blank"
                className="text-danger"
                to="https://aashirdigital.com/"
              >
                ~@aashirdigital
              </Link>
            </p>
          </small>
        </span>
      </div>
      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
    </>
  );
};

export default Announcement;
