import React, { useEffect, useRef, useState } from 'react';
import "./Leaderboard.css";
import { Link, useNavigate } from 'react-router-dom';
import HeroSvg from '../components/Layout/HeroSvg';
import SideMenu from '../components/Header/SideMenu';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveButton } from '../redux/features/footerMenuSlice';
import axios from 'axios';
import { SmileEmojiSvg, TimmerClockSvg } from '../components/AllSvgModulesExport/LoginSvg';
import gold from "../img/leaderboard/gold.png";
import silver from "../img/leaderboard/silver.png";
import bronze from "../img/leaderboard/bronze.png";
import { ChevronRightIcon, GiftSvg, LightningChargeSvg } from '../components/Header/AccountModalSvg';
import RewardDetailsModal from '../components/Modal/RewardDetailsModal';
import RewardPopupModal from '../components/Modal/RewardPopupModal';

const Leaderboard = () => {
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [sideMenu, setSideMenu] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [isLeaderboardPopupModalOpen, setIsLeaderboardPopupModalOpen] = useState(false); 
    const [tab, setTab] = useState(1); 

    useEffect(()=>{
        setTimeout(() => {
            setIsLeaderboardPopupModalOpen(true)
        }, 3000);
    },[])

    const onClose = () => {
        navigate('/');
        dispatch(setActiveButton('home'));
        localStorage.setItem('activeButton', 'home');
    };

    const [topUsers, setTopUsers] = useState([]);
    const [pastRewards, setPastRewards] = useState([]);
    const [timeLeft, setTimeLeft] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let interval;
        setLoading(true); 
        const fetchTopUsers = async () => {
            try {
                const { data } = await axios.get('/api/user/top-users', {
                    headers: {
                        'Cache-Control': 'no-cache',
                    },
                });

                const sortedTopUsers = data.formattedTopUsers
                .filter(user => user.position <= 20) // Include only users with position 1 to 20
                .sort((a, b) => a.position - b.position); // Sort in ascending order by position

                const sortedRecentRewards = data.formattedRecentRewards
                .filter(user => user.position <= 20) // Include only users with position 1 to 20
                .sort((a, b) => a.position - b.position); // Sort in ascending order by position

                setTopUsers(sortedTopUsers);
                setPastRewards(sortedRecentRewards);
        
                // Check if data is not empty
                if (data.formattedTopUsers.length > 0 && data.formattedTopUsers[0].weekEnd) {
                    const serverWeekEnd = new Date(data.formattedTopUsers[0].weekEnd); // Convert server time to Date object
                    calculateTimeLeft(serverWeekEnd); // Pass the correct weekEnd to the timer
                }
            } catch (error) {
                console.error('Error fetching top users', error);
            } finally {
                setLoading(false); 
            }
        };

        const calculateTimeLeft = (weekEnd) => {
            interval = setInterval(() => {
                const now = new Date();
                const difference = weekEnd - now;
                
                if (difference > 0) {
                    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                    const minutes = Math.floor((difference / 1000 / 60) % 60);
                    const seconds = Math.floor((difference / 1000) % 60);
                    setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
                } else {
                    clearInterval(interval);
                    setTimeLeft('Resetting...');
                    fetchTopUsers(); // Fetch again after reset
                }
            }, 1000);
        };
    
        fetchTopUsers();

        return () => {
            clearInterval(interval);
        };
    }, []);


    const getUserImage = (index, userName) => {
        if (index === 0) {
          return <img src={gold} width="40"alt="gold medal" loading="lazy"/>;
        } else if (index === 1) {
          return <img src={silver} width="40" alt="silver medal" loading="lazy"/>;
        } else if (index === 2) {
          return <img src={bronze} width="40" alt="bronze medal" loading="lazy"/>;
        } else {
          return (
            <div className="default-box rounded-s" >
              {/* {userName?.charAt(0)?.toUpperCase()} */}
              {index + 1}
            </div>
          );
        }
    };

    const truncateName = (name, maxLength = 20) => {
        return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
    };
      
    const handleUserClick = (user) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };

  return (
    <>
    <RewardDetailsModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        user={selectedUser} 
        tab={tab}
    />
    <RewardPopupModal isOpen={isLeaderboardPopupModalOpen} onClose={() => setIsLeaderboardPopupModalOpen(false)} />
     <div className="announcement-container d-lg-none d-md-none bg-white">
        {/* <HeroSvg color="red"/> */}
        <div className="account-modal-container-content" style={{backgroundColor: "#70b452"}}>
            <div className="top-left-content">
              <div className="box-1 center" onClick={onClose}>
                  <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                  <path
                      fillRule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                  </svg>
              </div>
              <h2 className="mb-0 text-white">Leaderboard</h2>
            </div>
            <div className="top-right-content box-2 center" onClick={() => setSideMenu(!sideMenu)}>
              <svg width="18" height="18" fill="currentColor" className="bi bi-list text-white" viewBox="0 0 16 16">
                  <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                  />
              </svg>
            </div>
        </div>

        <div className="leaderboard my-0 bg-light">
            <div className={`announcement-bottom-content-issue-facing bg-color mb-0 ${!loading? '' : ''} position-relative`}>
                <div className={`content p-0 `} style={{ minHeight: `${loading ? '75px' : 'auto'}` }}>
                    <div className='top-3-users-container w-100 p-3'>
                        <div className='top-3-users-content'>
                            <div className={`top-users-card shadow ${loading && 'loading overflow-hidden'} `} onClick={() => handleUserClick(topUsers[1])}>
                                <div className='users-profile-box'>
                                    <img src={silver} alt="silver medal" loading="lazy"/>
                                </div>
                                {topUsers[0]?.totalSpent && <h4 className={`text-center`}>₹{topUsers[1]?.totalSpent}</h4>}
                                <h5 className={`text-center`}>{topUsers[1]?.name? topUsers[1]?.name : 'No User Found'}</h5>
                                <div className='click-icon shadow'>
                                    <ChevronRightIcon fill='#ffffff'/>
                                </div>
                            </div>
                            <div className={`top-users-card middle-card shadow ${loading && 'loading overflow-hidden'} `} onClick={() => handleUserClick(topUsers[0])}>
                                <div className='users-profile-box middle-profile-box'>
                                    <img src={gold} alt="gold medal" loading="lazy"/>
                                </div>
                                {topUsers[0]?.totalSpent && <h4 className={`text-center`}>₹{topUsers[0]?.totalSpent}</h4>}
                                <h5 className={`text-center`}>{topUsers[0]?.name? topUsers[0]?.name : 'No User Found'}</h5>
                                <div className='click-icon shadow'>
                                    <ChevronRightIcon fill='#ffffff' />
                                </div>
                            </div>
                            <div className={`top-users-card shadow ${loading && 'loading overflow-hidden'} `} onClick={() => handleUserClick(topUsers[2])}>
                                <div className='users-profile-box'>
                                    <img src={bronze} alt="bronze medal" loading="lazy"/>
                                </div>
                                {topUsers[0]?.totalSpent && <h4 className={`text-center`}>₹{topUsers[2]?.totalSpent}</h4>}
                                <h5 className={`text-center`}>{topUsers[2]?.name? topUsers[2]?.name : 'No User Found'}</h5>
                                <div className='click-icon shadow'>
                                    <ChevronRightIcon fill='#ffffff'/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='reset-time-container p-3 center mt-3'>
                        <div className='reset-time-content center'>
                            <TimmerClockSvg />
                            <span className='center'>Challenge Reset in {timeLeft? timeLeft : 'Loading...'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {!loading && <div className='leaderboard-tab-container bg-light p-2 w-100'>
            <div className='leaderboard-tab-content p-2 d-flex gap-2 w-100 rounded-3 '>
                <div className=' w-100 center' onClick={()=>{setTab(1)}}>
                    <div className={`leaderboard-tab-button bg-white border-3 w-100 py-2 center rounded-3 shadow-sm border-bottom ${tab === 1? 'active': 'border-light'}`}>
                        <span className='text-dark fw-bold center gap-2'>
                            <div className='live-red-dot'></div>
                            <small>Active Challenge</small>
                            <LightningChargeSvg />
                        </span>
                    </div>
                </div>
                <div className='w-100 center' onClick={()=>{setTab(2)}}>
                    <div className={`leaderboard-tab-button bg-white border-3 w-100 py-2 center rounded-3 shadow-sm border-bottom ${tab === 2? 'active': 'border-light'}`}>
                        <span className='text-dark fw-bold center gap-2'>
                            <small>Past Reward</small>
                            <GiftSvg />
                        </span>
                    </div>
                </div>
            </div>
        </div>}
        
        {tab === 1 && <div className="leaderboard my-0">
            <div className={`mb-4 py-0 position-relative`}>
                <div className={`top-users-list ${loading && 'loading'}`} style={{ minHeight: '400px' }}>
                    {!loading && (
                        <div>
                            {Array.isArray(topUsers) && topUsers?.length > 3 ? ( // Check if there are more than 3 users
                                topUsers.slice(3).map((data, index) => ( // Slice array starting from index 3
                                    <div
                                        className={`row py-3 px-3 single-top-user border-bottom ${data.name === user?.name && 'bg-light'}`}
                                        key={index + 3}
                                        onClick={() => handleUserClick(data)}
                                    >
                                        <div className="d-flex px-3 gap-3 align-items-center">
                                            <div className="align-self-center">
                                                {getUserImage(index + 3, data?.name)} {/* Adjust index */}
                                            </div>
                                            <div className="align-self-center">
                                                <h5 className="mb-0">{data?.name}</h5>
                                            </div>
                                            <div className="align-self-center ms-auto text-end">
                                                <h4 className="color-green-dark no-wrap center mb-0 center">₹ {data?.totalSpent}</h4>
                                            </div>
                                            <div className='click-icon shadow'>
                                                <ChevronRightIcon fill='#000000'/>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className='text-center fw-bold mt-5'>No top users found</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>}
        {tab === 2 && <div className="leaderboard my-0">
            <div className={`mb-4 py-0 position-relative`}>
                <div className={`top-users-list ${loading && 'loading'}`} style={{ minHeight: '400px' }}>
                    {!loading && (
                        <div>
                            {Array.isArray(pastRewards) && pastRewards?.length > 0 ? ( // Check if there are more than 3 users
                                pastRewards.map((data, index) => ( // Slice array starting from index 3
                                    <div
                                        className={`row py-3 px-3 single-top-user border-bottom ${data.name === user?.name && 'bg-light'}`}
                                        key={index + 3}
                                        onClick={() => handleUserClick(data)}
                                    >
                                        <div className="d-flex px-3 gap-3 align-items-center">
                                            <div className="align-self-center">
                                                {getUserImage(index, data?.name)} {/* Adjust index */}
                                            </div>
                                            <div className="align-self-center">
                                                <h5 className="mb-0">{data?.name}</h5>
                                            </div>
                                            <div className="align-self-center ms-auto text-end">
                                                <h4 className="color-green-dark no-wrap center mb-0 center">{data?.reward}</h4>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className='text-center fw-bold mt-5'>No Rewards found</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>}


        <span className="center mt-4">
          <small>
            <p className='mb-0 fw-bold text-dark'>All Rights Reserved © 2024 | TOPUP PLAYGROUND</p>
            <p className='mb-0'>
              <span className='text-dark'>Website designed & developed by</span>
              <Link
                target="_blank"
                className="text-danger"
                to="https://wa.me/918624887338"
              >
                ~@waleedsdev
              </Link>
            </p>
          </small>
        </span>  
      </div>
      <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu}/>
    </>
  );
};

export default Leaderboard;
