import React from 'react';
import './WhyTopUpPlayground.css';

const WhyTopUpPlayground = () => {
  return (
    <div className='why-topupplayground-container'>
      <div className="container">
        <header className="why-topupplayground-header">
          <h1 className='mb-4 mb-lg-5 mt-5 mt-lg-0 mt-md-0'>Why top up games on Topupplayground?</h1>
          <p>
          Join millions of gamers who trust TopupPlayground for quick and secure game credit top-ups. Whether it's PUBG Mobile, Mobile Legends, or other popular games, your purchases are credited instantly—no hassle, no wait. Sign up or log in now and boost your gaming experience!
          </p>
        </header>
        <div className="why-topupplayground-features px-4 py-5 rounded-4 bg-white border">
          <Feature
            icon="quick-icon"
            title="Easy and Fast"
            description="It only takes a few seconds to complete a purchase on Topupplayground."
          />
          <Feature
            icon="delivery-icon"
            title="Instant Delivery"
            description="When you top-up on Topupplayground, your purchase is delivered directly to your game account as soon as your payment is complete."
          />
          <Feature
            icon="payments-icon"
            title="Convenient Payment Methods"
            description="To ensure your convenience, we have partnered with the most popular providers in India."
          />
          <Feature
            icon="support-icon"
            title="Speedy & Localized Customer Support"
            description="Our friendly customer support team is always available to assist you. Contact us!"
          />
          <Feature
            icon="promo-icon"
            title="Exciting Promotions"
            description="Keep a lookout for the best deals for your favourite games with Topupplayground promotions."
          />
          <Feature
            icon="rewards-icon"
            title="Get rewards on every purchase"
            description="Save on your Topupplayground purchases by signing up to enjoy our Coda Rewards loyalty program. Earn Coda Rewards on every purchase and use your rewards for discounts on your purchases. Learn more here."
          />
        </div>
      </div>
    </div>
  );
};

const Feature = ({ icon, title, description }) => {
  return (
    <div className="feature gap-3">
      <div className={`feature-icon bg-white border p-2 rounded-pill ${icon}`}></div>
      <div className='text-left w-100'>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default WhyTopUpPlayground;
